$text: #FAFAFA;

.c-drawer {
  .ant-drawer-header {
    background-color: #121212;
    .anticon.anticon-close svg {
      fill: $text;
    }

    border-bottom-color: #212121;
  }
  .ant-drawer-title {
    color: $text;
  }
  .ant-drawer-body {
    color: $text;
    background-color: #121212;
  }

  &__line-item {
    display: flex;
    justify-content: space-between;
    &:not(:first-child) {
      padding-top: 10px;
    }
    &:not(:last-child) {
      padding-bottom: 10px;
      border-bottom: solid #212121 1px;
    }
  }
}
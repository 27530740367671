.c-category-filter {
  border-radius: 4px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.56px;
  line-height: 18px;
  width: 100%;

  &__dropdown {
     li {
       color: #1A1A1A;
       font-family: Lato;
       font-size: 14px;
       letter-spacing: 0.56px;
       line-height: 18px;
       &.rc-select-dropdown-menu-item {
         overflow: auto;
         text-overflow: ellipsis;
       }
       &.rc-select-dropdown-menu-item-active {
        background-color: #EBEBEB;
      }
    }
  }

  .rc-select-selection--single {
    background: inherit;
    border-radius: inherit;
    height: 100%;
  }
  
  .rc-select-selection-selected-value {
    width: 90%;
    overflow: auto;
    text-overflow: ellipsis;
  }

  .rc-select-arrow .rc-select-arrow-loading:after {
    border: 2px solid white;
    border-color: white transparent white transparent;
  }
  .rc-select-arrow .rc-select-arrow-icon {
    border-color: white transparent transparent transparent;
  }
}

.c-dash-nav {
  font-family: Lato;
  margin-bottom: 28px;
  @include breakpoint(sm) {
    display: none;
  }  
  &__header {
    color: #1A1A1A;
    font-family: Lato;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  &__hyperlink {
    color: #242581;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 30px;
  }
}
.c-char-warning {
  &.hide {
    display: none !important;
  }

  &.green {
    color: green;
  }

  &.red {
    color: red;
  }
}

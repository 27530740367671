// thead, tbody, tr, td, th { display: block; }
// tr:after {
//     content: ' ';
//     display: block;
//     visibility: hidden;
//     clear: both;
// }

// tbody {
//     // height: 600px;
//     // overflow-y: auto;
// }
// thead {
//     /* fallback */
// }
// tbody td, thead th {
//     float: left;
// }

// .table-pagination {
//   text-align: center;
//   margin-top: 35px;
//   font-size: 2rem;
// }

// table {
//   &.board {
//     width: 100%;
  
//     thead {
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       border-radius: 0.5rem;
//       z-index: 2;
//       background-color: $white;
//       padding: 2rem 2rem 0;

//       tr:last-child {
//         border-bottom: 0.2rem solid #242424;
//       }
//       tr {
//         border-bottom: none;
  
//         th {
//           padding: 1rem;
//           font-size: 2rem;
//           text-align: center;
  
//           &:first-child {
//             text-align: left;
//             padding-left: 0;
//           }
//           &:last-child {
//             text-align: right;
//           }
//         }
//       }
//     }
  
//     tbody {
//       padding-top: 6rem;
//       padding-bottom: 1.6rem; 

//       tr {
//         color: rgb(81, 182, 31);
//         box-shadow: 0px 8px 0px 1px #E6E6E6;
//         border-radius: 1.6rem;
  
//         td {
//           font-size: 2rem;
//           padding: 3rem 2rem 1rem;
//           vertical-align: middle;
//           text-align: center;
  
//           &:first-child {
//             text-align: left;
//           }
//           &:last-child {
//             text-align: right;
//           }
  
//           span {
//             height: 100%;
//             padding: 0; 
//             // display: block;
//           }
//         }
//       }
//     }
//   }
// }

.c-vertical-table {
  padding: 0 18px;

  &__row {
    padding: 18px 0;
    &:not(:last-child) {
      border-bottom: 1px solid #E3E3E3;
    }
  }
  &__data-row {
    display: flex;

    &:not(:first-child) {
      padding-top: 10px;
    }
    &:not(:last-child) {
      padding-bottom: 10px;
    }
  }
  &__header {
    width: 150px;
    padding-right: 35px;
  }
  &__data {
    flex: 1;
  }
}
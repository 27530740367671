#deals-board {
  .header-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    @include breakpoint(sm) {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .c-deals-fiscal-date {
      border-bottom: solid 1px #E6E6E6;
      color: #696969;
      font: 12px/14px 'Lato';
      letter-spacing: 0.75px;
      margin-bottom: 12px;
      padding-bottom: 12px;
      &__date {
        color: #000000;
        font: bold 16px/17px 'Lato';
        letter-spacing: 0.2px;
        margin-top: 5px;
      }
    }
    .leftbox {
      flex: 0.49;
      border-radius: 3px;
      background-color: #ffffff;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
      padding: 25px 30px;
      max-height: 209px;
      overflow-y: auto;
      margin: 14px 0 16px;
      @include breakpoint(sm) {
        margin: 0;
      }
      .row {
        display: flex;
        margin-bottom: 14px;
        .leftcol {
          color: #000000;
          font-family: 'Lato';
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0.2px;
          line-height: 19px;
          flex: 1;
        }
        .rightcol {
          color: #000000;
          font-family: 'Lato';
          font-size: 18px;
          letter-spacing: 0.22px;
          line-height: 22px;
          font-weight: 400;
        }
      }
    }
    .rightbox {
      flex: 0.49;
      border-radius: 3px;
      background-color: #ffffff;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
      padding: 25px 30px;
      .row {
        display: flex;
        &:not(:first-child) {
          padding-top: 11px;
        }
        &:not(:last-child) {
          padding-bottom: 11px;
          border-bottom: 1px solid #e6e6e6;
        }
        .leftcol {
          color: #333333;
          font-family: 'Lato';
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0.2px;
          line-height: 19px;
          flex: 1;
        }
        .rightcol {
          color: #000000;
          font-family: 'Lato';
          font-size: 16px;
          letter-spacing: 0.2px;
          line-height: 19px;
          font-weight: 400;
        }
      }
    }
  }
  .element-container,
  .element-container-fixed {
    background-color: #ffffff;
    .inner-container {
      width: 100%;
      display: flex;
      border-radius: 3px;
      .cell {
        @extend %font-16r;
        color: inherit;
        display: flex;
        align-items: center;
        @include breakpoint(sm) {
          padding-right: 5px;
          height: 100%;
        }
        @include breakpoint(xxl) {
          &:last-child {
            flex: 0.6 !important;
          }
        }
        .menu {
          position: relative;
          &:hover {
            &::after {
              position: absolute;
              font-family: 'Font Awesome 5 Free';
              font-weight: 300;
              content: '\f2c2';
              position: absolute;
              // right: -5px;
              // top: 8px;
              font-size: 16px;
              // margin-left: 7px;
              color: #173261;
              transform: translateX(10px);
            }
          }
        }
        a,
        .c-link-button {
          color: inherit !important;
          &:hover {
            color: rgb(0, 0, 238) !important;
            text-decoration: underline;
          }
        }
      }
    }
    &.header {
      padding-top: 0;
      padding-bottom: 0;
      position: fixed;
      z-index: 5;
      left: 10px;
      width: 100%;
      top: 275px;
      .element-container-fixed {
        margin-bottom: 0px;
        .inner-container {
          background-color: #f8f8f8;
          height: 20px;
          box-shadow: none;
          .cell {
            @extend %font-16;
            color: #222222;
            font-weight: bold;
          }
        }
      }
    }
  }
  .element-container-fixed {
    display: none;
    margin: 12px 0;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    @include breakpoint(sm) {
      display: flex;
    }
    .inner-container {
      padding: 15px 40px;
    }
  }
  .element-container {
    display: flex;
    border: 1px solid #DBDBDB;
    background-color: #ffffff;
    padding: 20px;
    &:not(:last-child) {
      border-bottom: none;
    }
    .inner-container {
      display: block;
      padding: none;
      .mobile-name {
        line-height: 38px;
        white-space: nowrap;
        color: #444444;
        font-family: "Open Sans";
        font-size: 14px;
        width: 0;
        padding-right: 123px;

        @include breakpoint(sm) {
          display: none;
        }
      }
      .cell {
        // height: 38px;
        button {
          text-align: left;
          padding: 0;
        }
      }
    }
    @include breakpoint(sm) {
      border: none;
      padding: 0;
      margin: 12px 0;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
      .name-container {
        display: none;
      }
      .inner-container {
        display: flex;
        padding: 15px 40px;
        .cell {
          height: initial;
        }
      }
    }
  }

  .c-filters {
    display: flex;
    padding: 20px 0;
    justify-content: flex-end;

    &__item {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}

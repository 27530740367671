#jobs-board {
  &.headerless {
    padding-bottom: 0;

    // overflow: visible;
    .element-container {
      margin: 10px 0px;

      .inner-container {
        .job-block {
          padding: 30px 30px 30px 40px;
        }
      }

    }
    .c-btn--responsive {
      min-width: 20px;
      min-height: 20px;
    }
  }

  &.board-container--header {
    @include breakpoint(xxl) {
      height: initial;
      column-count: 2;
      column-gap: 48px;

      .element-container {
        break-inside: avoid;
      }
    }

    .element-container {
      .inner-container {
        .job-block {
          padding: 12px;
          @include breakpoint(sm) {
            padding: 20px 28px;
            padding-right: 20rem;
          }

          .job-line {
            &.webapp {
              .topright-info {
                $border: 1px solid #d8d8d8;
                padding: 12px 0;
                height: 100%;
                right: 0px;
                border-top: $border;
                border-bottom: $border;
                border-left: none;
                width: auto;

                @include breakpoint(sm) {
                  border-top: none;
                  border-bottom: none;
                  border-left: $border;
                  padding: 20px 28px;
                  width: 18rem;
                }
              }

              h2.client-corporation-name {
                width: 100%;

                @include breakpoint(sm) {
                  width: fit-content;
                }
              }

              h2.job-info {
                position: relative;
                padding-left: 30px;
                padding-bottom: 15px;

                @include breakpoint(sm) {
                  padding: 0;
                }

                button {
                  position: absolute;
                  left: -15px;
                  top: -13px;

                  @include breakpoint(sm) {
                    position: initial;
                  }
                }
              }
            }

            .topright-info {
              position: relative;
              @include breakpoint(sm) {
                position: absolute;
              }
            }

            h2 {
              @include breakpoint(sm) {
                display: inline-block;
              }
            }
          }

          .submissions {
            margin-top: 20px;

            .submissions-row {
              &__entry {
                margin: 0 0.5rem 1rem 0;

                @include breakpoint(sm) {
                  margin: 0.5rem 1rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .element-container {
    .inner-container {
      .job-block {
        &.isRTC {
          margin-top: 70px;
        }
      }
    }
  }

  .element-container~.element-container {
    margin-top: 0px;

    .inner-container {
      .job-block {
        &.isRTC {
          margin-top: 40px;
        }
      }
    }
  }

  .element-container {
    display: flex;
    margin-bottom: 2rem;

    .inner-container {
      width: 100%;
      padding: 0px 0px;
      display: flex;
      flex-direction: column;
      border-radius: 3px;

      .tabs-container {
        display: flex;

        .toptab {
          border-radius: 3px 3px 0 0;
          padding: 8px 15px;
          display: flex;
          width: 100px;
          justify-content: space-around;
          margin-left: 5px;

          &:first-child {
            margin-left: 0;
          }

          &.rtc {
            background: #000;
          }

          &.multi-division {
            position: relative;
            background: #60678B;
            width: 158px;
            .MuiSvgIcon-root {
              height: 19px;
              color: white;
            }
          }
          &.active-placement {
            position: relative;
            background: #604A4B;
            width: 180px;
            .MuiSvgIcon-root {
              height: 19px;
              color: white;
            }
          }

          p {
            color: #ffffff;
            font-family: 'Lato';
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 0.44px;
            line-height: 19px;
          }
        }
      }

      .job-block {
        width: 100%;
        padding: 20px 28px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background-color: #ffffff;
        box-shadow: 0 1px 9px 0 rgb(0 0 0 / 20%);
        position: relative;
        padding-right: 20rem;
        border-bottom: 2px solid #d8d8d8;
        min-height: 145px;

        &__title {
          font-weight: bold;
        }

        &.isRTC {
          margin-top: 40px;
        }

        .job-line {
          &.webapp {

            a,
            h2 {
              font-size: 16px;
              margin-bottom: 0rem;
            }

            .topright-info {
              top: 0px;
              border-left: 1px solid #d8d8d8;
              padding: 20px 28px;
              height: 100%;
              width: 18rem;
              right: 0px;
            }

            h2.city {
              width: auto;
              display: block;
              white-space: break-spaces;
              overflow-x: hidden;
              text-overflow: ellipsis;
            }

            h2.title {
              font-weight: bold;
              width: auto;
              display: block;

              a {
                font-weight: bold;
              }
            }
          }

          .topright-info {
            position: absolute;
            top: 15px;
            right: 40px;

            h2 {
              white-space: pre;
              color: #000000;
              font-family: 'Lato';
              font-size: 16px;
              letter-spacing: 0.64px;
              line-height: 24px;
              margin-bottom: 0rem;

              a {
                border-bottom: 1px solid black;
                z-index: 9999;
              }

              span {
                color: #acacac;
              }
            }
          }

          h2 {
            display: inline-block;
            width: fit-content;
            font-size: 25px;
            line-height: 30px;
            cursor: default !important;
          }

          .c-open-times-icon {
            cursor: pointer;
            display: inline-block;
            height: 20px;
            margin: 0 5px;
            position: relative;
            width: 20px;

            svg {
              position: absolute;
              top: 4px;
            }
          }
        }

        .submissions {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .hot {
            &__entry {
              width: 33% !important;
            }
          }

          .submissions-row {
            display: flex;
            justify-content: flex-start;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: wrap;

            &.hot {
              .submissions-row__entry {
                width: 33%;
                margin-bottom: 25px;

                span {
                  .hotInfo {
                    font-weight: 400;
                    font-size: 16px;
                  }
                }
              }
            }

            &__entry {
              margin: 0.5rem 1rem;
              display: flex;
              align-items: center;
              font-weight: 400;
              position: relative;

              span {
                white-space: nowrap;
              }

              &__icon {
                height: 20px;
                cursor: pointer;

                span {
                  &:hover {
                    &::after {
                      display: none;
                    }
                  }
                }

                .status {
                  margin-right: 8px;
                }
              }

              &__ref-check {
                margin-right: 8px;
              }

              span:nth-child(1) {
                cursor: pointer;

                &:hover {
                  &::after {
                    font-family: 'Font Awesome 5 Free';
                    font-weight: 300;
                    content: '\f2c2';
                    position: absolute;
                    // right: -5px;
                    top: 8px;
                    font-size: 16px;
                    // margin-left: 7px;
                    color: #173261;
                    transform: translateX(10px);
                  }
                }
              }

              // status glyphs
              .status {
                cursor: pointer;
              }
            }
          }
        }
      }

      .job-block-actions {
        height: auto;
        padding: 4px;
        width: 100%;
        border-radius: 0 0 3px 3px;
        background-color: #f6f6f6;
        box-shadow: 0 2px 6px 0 rgb(0 0 0 / 20%);
        display: flex;
        flex-direction: column;
        z-index: 1;
        justify-content: center;

        @include breakpoint(lg) {
          flex-direction: row;
          justify-content: space-between;
        }

        &__timestamps {
          align-self: flex-start;
          color: #555555;
          font-family: Lato;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 24px;

          span {
            display: inline-block;
            padding: 0 15px;
            white-space: nowrap;
          }
        }

        .hide-button {
          align-self: flex-end;
          bottom: 15px;
          right: 40px;
          color: #222222;

          span {
            transform: translate(-3px, 6px);
          }

          p {
            color: #222222;
            font-family: 'Lato';
            font-size: 12px;
            letter-spacing: 0.64px;
            display: inline;
            vertical-align: text-bottom;
            line-height: 30px;
          }

          .c-link-button {
            margin: 0px auto;
            padding: 0px 15px;
            display: inline-flex;
            align-items: center;

            span {
              color: #222222;
            }
          }

          svg {
            transform: translate(0px, 4px);
            margin-right: 5px;
          }
        }
      }
    } 
  }
}
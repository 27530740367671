.main-nav {
  ul {
    justify-content: flex-end;
    display: flex;
  }

  &__item {
    display: inline-block;
    width: 22.5%;
    margin-right: 1rem;
    &:last-of-type {
      width: 5%;
      margin: 0;

      span {
        margin: 0;
      }
    }

    a {
      height: 60px;
      // border: 1px solid $light-white;
      // border-radius: 5px;
      // padding: 2rem;
      // text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $black;
      text-transform: capitalize;
      font-size: 1.8rem;
      position: relative;


      span {
        color: $black;
        margin-right: 15px;
        vertical-align: middle;
      }
    }

    &.-active {
      a {
      //  background-color:  rgba(255, 255, 255, .9);
        color: #464742;
        position: relative;
      //  pointer-events: none;

        &::after,
        &::before  {
          // content: "";
          position: absolute;
          bottom: -30px;
          width: 20px;
          height: 30px;
          // background-color:  rgba(255, 255, 255, .9);
        }
        &::after {
          right: calc(50% - 10px);
        }
        &::before {
          left: calc(50% - 10px);
        }

        span {
          color: #464742 !important;
        }
      }
    }
  }

  &__activity {
    // margin: 0;
    // width: 17%;

    a {
      // border-radius: 5px 0 0 5px;
      
    }

    &.-active {
      a {
        border-right-color: #464742;
      }
    }
  }

  &__activity-dropdown {
    width: 5%;

    a {
      border-radius: 0 5px 5px 0;
    }
  }

  &__chevron {
    // position: absolute;
    // right: 0;
    // display: inline-block;
    // border-left: 1px solid $light-white;
    // height: 100%;
    // top: 0;
    // padding: 7% 5%;
    // margin-right: 0 !important;

    span {
      margin-right: 0 !important;
    }
  }
  #activity-settings,
  #menu-settings {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
}
.c-notice {
  &__error {
    color: black;
    background: white;
    border: #F05050 solid 2px;
    border-radius: 5px;

    .Toastify__close-button {
      color: #F05050;
    }
  }

  &__success {
    font-family: 'Lato';
    background: white;
    color: black;

    .Toastify__close-button {
      color: black;
    }
  }

  &__progress-bar {
    &--success {
      background-color: #F05050;
    }
  }

  &__msg {
    display: flex;
    align-items: center;
  }
}

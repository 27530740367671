#assigned-accounts {
  overflow: auto;
  &.board-container.board-container--header {
    @include breakpoint(xxl) {
      height: initial;
      column-count: 2;
      column-gap: 48px;
      .assigned-accounts-card {
        break-inside: avoid;
        // strange bug in Safari 13.1 that causes overflowing content to disappear in the second+ columns
        // translateX(0); fixes it
        // https: //stackoverflow.com/questions/29624396/when-using-column-count-overflowing-content-completely-disappears-in-all-but-fi
        transform: translateX(0);
      }
    }
  }
}

.assigned-accounts-card {
  border-radius: 3px;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
  width: 100%;
  margin-bottom: 20px;

  &:last-child {
    margin: 0;
  }
}

.assigned-accounts-card-table {
  display: none;
  @include breakpoint(lg) {
    display: block;
  }
  & table {
    table-layout: fixed;
  }
  & .ant-table-content {
    overflow: auto;
  }
  &--vertical {
    display: block;
    background: #fff;
    @include breakpoint(lg) {
      display: none;
    }
    & .c-vertical-table {
      &__header {
        color: #4A4A4A;
        font-weight: bold;
        letter-spacing: 1px;
        @include breakpoint(sm) {
          width: 232px;
        }
      }
      &__data, &__data .header-username.right {
        text-align: left;
        color: #000000;
        font-family: Lato;
        font-size: 16px !important;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 18px;
      }
    }
  }
}

.assigned-accounts-card-table-cell {
  padding: 10px 32px !important;
  height: 60px !important;
  max-height: 60px !important;
  color: #4A4A4A;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.56px;
  line-height: 18px;
  vertical-align: middle;
}

.assigned-accounts-card-table-cell-text {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.56px;
  line-height: 18px;
}

tbody .ant-table-tbody{
  padding: 32px !important;
}

.ant-table-tbody {
  > tr:last-child {
    > td {
      border: none !important;
    }
  }
}

.assigned-accounts-card-table-row {
  margin: 32px !important;
  width: 80% !important;
  height: 60px !important;
  // > tr {
  // background-color: red !important;
  // background: red !important;
  // }
  &:hover {
    > td {
      background-color: transparent !important;
      background: transparent !important;
    }
  }
}

.ant-card-head-title {
  padding: 10px 0 !important;
  height: 56px;
  display: flex;
  overflow: visible !important;
  align-items: center;
}

.assigned-accounts-card-table-title {
  cursor: auto;
  color: #000000;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 24px;
}

.assigned-accounts-card-table-active-placements-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: auto;
}

.assigned-accounts-card-table-active-placements-title {
  cursor: auto;
  color: #4A4A4A;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.56px;
  line-height: 18px;
  margin-right: 8px;
}

.assigned-accounts-card-table-active-placements-number {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.56px;
  line-height: 18px;
}
.assigned-accounts-card-table-header {
  padding: 15px 32px !important;
  vertical-align: middle;
  background-color: white !important;
  border: 0px solid transparent !important;
  color: #4A4A4A !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: bold !important;
  letter-spacing: 1px !important;
  line-height: 18px !important;

  > tr > th {
    cursor: pointer;
  }
}

.ant-table-column-sorter {
  vertical-align: baseline !important;
}

.ant-table-column-sorters-with-tooltip .ant-table-column-sorters {
  padding-left: 0;
}

.ant-card-body {
  padding: 0 !important;
}

.assigned-accounts-card-header-user-cell {
  display: flex;
  align-items: center;
}

.assigned-accounts-toggle-container {
  display: flex;
  margin: 10px 0;
  flex-direction: column;
  @include breakpoint(lg) {
    margin: 10px 0 10px 10px;
  }
}

.assigned-accounts-toggle {
  height: 40px;
  border-radius: 4px;
	border: 1px solid #D8D8D8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0;
  margin: 0;
  svg {
    display: none;
    @include breakpoint(lg) {
      display: block;
    }
  }
  @include breakpoint(lg) {
    height: 34px;
    width: 286px;
  }
}

.react-switch-icon-container {
  height: 40px;
  border-radius: 4px;
  display: flex;
  flex-grow: 1;
  z-index: 8;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include breakpoint(lg) {
    height: 34px;
  }
  
  &.active {
    background: linear-gradient(270deg, #FB2F2F 0%, #D80808 100%);
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
  }
}

.react-switch-icon-container-icon {
  margin-right: 5px;
  fill: rgba(0,0,0,0.4);

  &.active {
    fill: white;
  }
}

.react-switch-icon-container-text {
  color: rgba(0,0,0,0.4);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.56px;
  line-height: 18px;
  font-weight: bold;
  user-select: none;

  &.active {
    color: white;
  }
}

.ant-collapse-content-box {
  border-top: 1px solid #E3E3E3;
  padding: 0 !important;
  // padding: 0 32px !important;
}

.ant-collapse-header {
  padding: 0 !important;
  display: flex;
  flex-direction: row;
  min-height: 60px;
}

.ant-collapse-header-inner {
  cursor: row-resize;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 10px 32px !important;
}

.ant-table-placeholder {
  border: 0px solid transparent !important;
}

.ant-collapse-item {
  border: 0 !important;
}

.ant-table-column-title {
  user-select: none;
}

.ant-card-bordered {
  border: 0 !important;
}

.assigned-accounts-empty-ellipses {
  width: 100%;
  padding: 10px 0;
  text-align: center;
  user-select: none;
}

.ant-table-column-sorters {
  display: flex;
  flex-direction: row;
}

.ant-table-column-sorter {
  cursor: pointer;
  margin-left: 10px;
}

.antd-tooltip-text-style {
  user-select: none;
  color: #fff;
  font-family: Lato;
  font-weight: bold;
  line-height: 24px;
}

.ant-table-column-sort {
  background: #f2f2f2 !important;
}
.logo-container {
  background-color: $white;
  width: 100%;
  padding-top: 30px;
  display: flex;
  justify-content: center;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .logo-container {
    .logo-image {
      width: 84.58px;
    }
  }
}
@media (max-width: 1280px) {
  .logo-container {
    padding-top: 10px;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 10px;
    .logo-image {
      width: 111px;
      height: auto;
    }
  }
}

.login-container {
  height: 100vh;
  background-color: #fff;
}

.user-selection-main {
  background: #ffffff;
  padding: 28px 0;
  text-align: center;
  @include breakpoint(sm) {
    padding: 60px 0;
  }

  .user-selection-inner {
    width: 100%;
    margin: auto;
    max-width: 1000px;
    text-align: center;

    @include breakpoint(xxl) {
      max-width: 1080px;
    }
  }

  .change-dept-button {
    width: 85px;
    font-size: 12px;
    letter-spacing: 0;
    padding: 6px 8px;
    position: absolute;
    user-select: none;
    top: 15px;
    left: 12px;
    background: rgb(238, 238, 238);
    cursor: pointer;
    &:hover {
      background: rgb(212, 245, 255);
    }

    @media screen and (min-width: 500px) {
      width: auto;
    }

    @include breakpoint(sm) {
      left: 18px;
      font-size: 15px;
      padding: 5px 10px;
    }
    @include breakpoint(lg) {
      left: 20px;
      font-size: 100%;
      top: 20px;
    }
  }
}

.department-card-container {
  margin: 0;
  max-width: none;
  align-items: center;
  text-align: center;
  cursor: pointer;

  &.right {
    display: flex;
  }

  &.bottom {
    vertical-align: top;
    margin: 15px;
    max-width: 96px;
    display: inline-block;
    text-align: center;

    @include breakpoint(sm) {
      max-width: 120px;
    }
    @include breakpoint(xxl) {
      max-width: 150px;
    }
  }

  &.pointer-cursor {
    cursor: pointer;
  }
}

.department-card-gradient-container {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  @include breakpoint(sm) {
    width: 120px;
    height: 120px;
  }
  @include breakpoint(xxl) {
    width: 150px;
    height: 150px;
  }

  &.department-card-margin {
    margin: 15px;
    display: inline-flex;
  }

  &--headerless {
    height: 120px;
    width: 120px;
    min-width: 120px;
    min-height: 120px;
  }
}

.department-card {
  overflow: hidden;
  border-radius: 50%;
  width: 87px;
  height: 87px;
  position: relative;
  cursor: pointer;
  background-color: $white;

  @include breakpoint(sm) {
    width: 110px;
    height: 110px;
  }
  @include breakpoint(xxl) {
    width: 137px;
    height: 137px;
  }

  &--headerless {
    height: 110px;
    width: 110px;
    min-width: 110px;
    min-height: 110px;
  }

  .user {
    border: none;
    display: block;
  }

  .users-name {
    position: absolute;
    font-weight: 600;
    user-select: none;
    width: 100%;
    text-align: center;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .card-image {
    width: 110px;
    height: 110px;
    object-fit: cover;
  }
}

.header-username {
  color: #000000;
  font-family: Lato;
  font-size: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  letter-spacing: 0.96px;
  line-height: 24px;
  margin-left: 20px;

  &.bottom {
    margin: 15px 0;
  }
}

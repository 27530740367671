@import './dashboard/cards';
@import './dashboard/sendouts';
@import './dashboard/deals';
@import './dashboard/dashNav';

.c-dashboard {
  padding: 18px 32px;
  @include breakpoint(lg) {
    padding: 29px 83px;
  }
  &:nth-child(2) {
    padding-top: 0;
  }

  &--sendouts {
    .c-dashboard__content--deals {
      display: none;
      @include breakpoint(xxl) {
        display: flex;
      }
    }
  }

  &--deals.c-dashboard--header {
    @include breakpoint(xxl) {
      .c-dashboard__content--deals {
        display: none;
      }
    }
  }

  &.c-dashboard--sendouts.c-dashboard--header {
    @include breakpoint(xxl) {
      .c-dashboard__content {
        display: inline-flex;
        width: 50%;
        > div {
          width: 50%;
        }
        &__left {
          border-right: none;
        }
        &--sendouts {
          padding-right: 18px;
        }
        &--deals {
          padding-left: 18px;
        }
      }
    }
  }

  &__title {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 1px;
    color: #1A1A1A;
    font-weight: bold;
    text-transform: capitalize;

    &--sm {
      font-size: 12px;
    }
  }

  &__updatedate {
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 14px;
    color: #949494;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    &--tight {
      margin-bottom: 20px;
    }

    @include breakpoint(lg) {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 1.8px;
      margin-bottom: 40px;
      >div {
        flex: 1;
        height: 1px;
        margin-left: 10px;
        border-top: 1px solid #CFCFCF;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    @include breakpoint(lg) {
      flex-direction: row;
      > div {
        flex: 1;
      }
      &__left {
        padding-right: 30px;
        border-right: 1px solid #D8D8D8;
      }
      &__right {
        padding-left: 30px;
      }
    }
    @include breakpoint(xxl) {
      flex-direction: row;
      > div {
        flex: 1;
      }
      &__left {
        padding-right: 30px;
        border-right: 1px solid #D8D8D8;
      }
      &__right {
        padding-left: 30px;
      }
    }
  }

  &__dealprogress {
    margin: 8px 0;
  }

  &__color {
    &--red {
      color: #F05050;
    }
    &--yellow {
      color: #FACF5D;
    }
    &--gold {
      color: #FFCE4C;
    }
    &--blue {
      color: #58B4CE;
    }
    &--skyblue {
      color: #4CA8D1;
    }
    &--turblue {
      color: #54C5E3;
    } 
    &--lightblue {
      color: #4CD1D9;
    }
    &--darkblue {
      color: #4161AF;
    }
    &--airblue {
      color: #2C6FD9;
    }
    &--navyblue {
      color: #242581;
    }
    &--green {
      color: #5BC045;
    }
    &--lightgreen {
      color: #14D2B0;
    }
    &--darkgreen {
      color: #108F79;
    }
    &--purple {
      color: #C26592;
    }
    &--grape {
      color: #7A36D1;
    }
    &--grapeA {
      color: #5A2AEE;
    }
    &--grapeB {
      color: #7324D8;
    }
    &--grapeC {
      color: #A629CB;
    }
    &--orange {
      color: #FFA155;
    }
    &--division {
      color: #04060F;
    }
    &--brand {
      color: #0294A5;
    }
    &--sub-brand {
      color: #7743A7;
    }
    &--alku {
      color: #C1403D;
    }
    &--hopbush {
      color: #C26592;
    }
    &--royal-blue {
      color: #4C4DD9;
    }
    &--celtic-blue {
      color: #2C6FD9;;
    }
  }
  &__bgcolor {
    &--red {
      background-color: #F05050;
    }
    &--yellow {
      background-color: #FACF5D;
    }
    &--blue {
      background-color: #58B4CE;
    }
    &--skyblue {
      background-color: #4CA8D1;
    }
    &--turblue {
      background-color: #54C5E3;
    } 
    &--lightblue {
      background-color: #4CD1D9;
    }
    &--darkblue {
      background-color: #4161AF;
    }
    &--airblue {
      background-color: #2C6FD9;
    }
    &--navyblue {
      background-color: #242581;
    }
    &--purple {
      background-color: #C26592;
    }
    &--orange {
      background-color: #FFA155;
    }
    &--gray {
      background-color: #F8F8F8;
    }
    &--royal-blue {
      background-color: #4C4DD9;
    }
  }

  &__page {
    position: fixed;
    font-size: 25px;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__pause {
    bottom: 5%;
    left: 5%;
    text-shadow: 0 0 70px rgba(0,0,0,0.2);
    z-index: 9999;
  }

  &__update-date {
    justify-content: space-between;
    @include breakpoint(sm) {
      display: flex;
    }

    .c-filters {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      @include breakpoint(lg) {
        margin-bottom: 40px;
      }
    
      &__item {
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }
  }
}

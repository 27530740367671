.g-boardcard {
  background-color: #F8F8F8;
  box-shadow: 0 1px 17px 0 rgba(0,0,0,0.1);
  border-radius: 3px;
}

.g-cardtitle {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 2px;
  color: #1A1A1A;
  font-weight: 600;
  margin-bottom: 17px;

  &--regular {
    font-weight: 400;
  }
}

.g-column-sendouts {
  > div:not(:last-child) {
    border-bottom: 1px solid #E6E6E6;
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    > div {
      flex: 1;
      padding: 0 5px;
    }
  }
  &__value {
    text-align: right;
    font-size: 30px;
    line-height: 40px;
  }
  &__label {
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0.75px;
    color: #4E4E4E;
    margin-top: 6px;
  }
}

.g-progressbar {
  display: flex;
  align-items: center;
  height: 32px;
  border-left: 2px solid #242581;
  > div {
    height: 19px;
    border-radius: 0 9.5px 9.5px 0;
  }
  > div:not(:last-child) {
    margin-right: -9.5px;
  }

  &__labels-container {
    font-size: 10px;
    div {
      border: none;
      display: block;
      text-align: right;
    }
  }

  &__text {
    color: #EEECF4;
    font-size: 10px;
    margin-right: 6px;
  }

  &--separate {
    &:not(:last-child) {
      margin-bottom: 11px;
    }
  }

  &--bg {
    background-color: #F8F8F8;
    margin-left: 4px;
  }
}

.g-sendouts {
  padding: 12px 12px 20px 12px;
  &__title {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 1px;
    color: #1A1A1A;
    margin-bottom: 20px;
    font-weight: bold;
    text-transform: capitalize;
  }
  &__data {
    display: flex;
    > div {
      flex: 1;
      height: 65px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    > div:not(:last-child) {
      border-right: 1px solid #E6E6E6;
    }
  }
  &__number {
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    &--confirmed {
      color: #D65F70;
    }
    &--remaining {
      color: #302BC1;
    }
  }
  &__label {
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: .75px;
    color: #696969;
  }
}

.g-numbercard {
  display: flex;
  align-items: center;
  padding: 20px;
  &--sm {
    padding: 15px 0;
  }
  &--xs {
    padding: 6px 0;
  }
  &__value {
    font-size: 40px;
    line-height: 40px;
    color: #110731;
    border: 2px solid #CFCFCF;
    border-radius: 5px;
    width: 70px;
    height: 70px;
    padding-top: 14px;
    margin-right: 16px;
    > div {
      width: 66px;
      text-align: center;
    }

    &--xs {
      text-align: center;
      align-items: center;
      display: flex;
      font-size: 20px;
      height: 40px;
      padding-top: 0;
      width: 40px;
    }
  }
  &__labels {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__title {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 1px;
    font-weight: 600;
    color: #1A1A1A;
  }
  &__division {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: .88px;
    margin-top: 2px;
  }
  &--red {
    .g-numbercard__value {
      border-color: #F05050;
    }
    .g-numbercard__division {
      color: #F05050;
    }
  }
  &--yellow {
    .g-numbercard__value {
      border-color: #FACF5D;
    }
    .g-numbercard__division {
      color: #FACF5D;
    }
  }
  &--gold {
    .g-numbercard__value {
      border-color: #FFCE4C;
    }
    .g-numbercard__division {
      color: #FFCE4C;
    }
  }
  &--blue {
    .g-numbercard__value {
      border-color: #58B4CE;
    }
    .g-numbercard__division {
      color: #58B4CE;
    }
  }
  &--skyblue {
    .g-numbercard__value {
      border-color: #4CA8D1;
    }
    .g-numbercard__division {
      color: #4CA8D1;
    }
  }
  &--lightblue {
    .g-numbercard__value {
      border-color: #4CD1D9;
    }
    .g-numbercard__division {
      color: #4CD1D9;
    }
  }
  &--darkblue {
    .g-numbercard__value {
      border-color: #4161AF;
    }
    .g-numbercard__division {
      color: #4161AF;
    }
  }
  &--purple {
    .g-numbercard__value {
      border-color: #C26592;
    }
    .g-numbercard__division {
      color: #C26592;
    }
  }
  &--royal-blue {
    .g-numbercard__value {
      border-color: #4C4DD9;
    }
    .g-numbercard__division {
      color: #4C4DD9;
    }
  }
  &--orange {
    .g-numbercard__value {
      border-color: #FFA155;
    }
    .g-numbercard__division {
      color: #FFA155;
    }
  }
}

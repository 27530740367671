.c-wysiwyg {
  padding: 0;
  strong {
    font-weight: bold;
  }

  // hides bullet points on skeleton
  .ant-skeleton-content .ant-skeleton-paragraph>li {
    &::before {
      display: none;
    }
  }
}
.top-filters {
  background: rgb(252, 252, 252);
  width: 100%;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  padding: 20px 60px;
  z-index: 9;
  flex-wrap: wrap;

  .alku-filter {
    margin-right: 25px;
    span {
      margin-right: 10px;
      color: #000000;
      font-family: Lato;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.56px;
      line-height: 18px;
    }
    .ant-select-selection__choice__remove {
      margin-right: 0;
    }
  }
}

.board-container {
  background: #F8F8F8;
  height: 100%;
  margin: auto;
  padding: 1% 18px;
  width: 100%;
  @include breakpoint(lg) {
    padding: 1% 83px;
    margin: 0;
  }
  @include breakpoint(xxl) {
    margin: 0 auto;
    max-width: 3236px;
    padding: 0;
  }
  
  &.headerless {
    height: 100vh;
    overflow: hidden;
    padding: 1%;
  }
}

.headerless-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 20px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
  color: #000000;
  font-family: Lato;
  font-size: 24px;
  letter-spacing: 0.96px;
  line-height: 29px;
  text-align: right;
  span {
    margin-right: 5px;
  }
  img {
    height: 42px;
  }
}

.c-content-layout {
  background: #F8F8F8;;
  &.bottom {
    &::after {
      position: fixed;
      bottom: 0;
      content: '';
        background: linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 0.25) 100%);
      height: 30px;
      width: 100%;

      @include breakpoint(lg) {
        display: none;
      }
    }
  }
}
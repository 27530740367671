.c-link {
  color: #3074d2;
  text-decoration: underline;
  font-weight: bold;
  text-decoration-color: #3074d2;
}
a {
  color: #3074d2;
  font-weight: bold;
  text-decoration-color: #3074d2;
}

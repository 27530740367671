.filter-header {
  min-height: 80px;
  padding: 0 18px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  @include breakpoint(lg) {
    border-top: 1px solid rgba(216, 216, 216, 0.3);
    flex-direction: row;
    padding: 0 83px;
  }
  @include breakpoint(xxl) {
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 3236px;
    padding: 0;
  }

  &.starts-management {
    @include breakpoint(sm) {
      flex-direction: row;
    }
    @include breakpoint(lg) {
      max-width: 1490px;
    }
    @include breakpoint(xxl) {
      margin-left: 83px;
    }
  }

  &--dropdown {
    background: #F7F7F7;
  }

  &.mobile-hidden:not(.activity) {
    .filter-header-input-list, .assigned-accounts-toggle-container, & + .filter-header-collapse {
      display: none;
      @include breakpoint(sm) {
        display: flex;
      }
    }
  }

  &__mobile-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    height: 44px;
    border: 1px solid #8E8E8E;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #4E4E4E;
    font-weight: bold;
    @include breakpoint(sm) {
      display: none;
    }
    svg {
      margin-left: 8px;
    }
    &:active {
      box-shadow: inset 0px 0px 5px #c1c1c1;
    }
  }
}
  
.filter-header-input-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  @include breakpoint(sm) {
    flex-direction: row;
  }

  &--right {
    align-items: center;
    flex: initial;
    @include breakpoint(lg) {
      margin-top: 26px;
      justify-content: flex-end;
    }
    .filter-header-input {
      width: 100%;
      .c-sort-dropdown {
        width: 100%;
        @include breakpoint(lg) {
          width: 200px;
        }
      }
      @include breakpoint(sm) {
        width: initial;
      }
    }
  }

  &--jobs {
    &.filter-header-input-list--right {
      .filter-header-input.open-times, .filter-header-input.nbsp {
        display: block;
        @include breakpoint(lg) {
          display: none;
        }
      }
    }
    .filter-header-input.open-times {
      display: none;
      @include breakpoint(lg) {
        display: block;
      }
    }
  }

  &--starts-mngmt {
    justify-content: flex-end;
  }

  &--starts {
    .filter-header-input.starts-toggle {
      margin-top: 26px;
      margin-bottom: 0;
    }
    &.filter-header-input-list {
      flex-wrap: wrap;
    }
    .filter-header-input {
      @include breakpoint(sm) {
        flex: 30%;
      }
      @media (min-width: 768px) and (max-width: 1279.99px) {
        &:nth-child(4) {
          margin-left: 0;
        }
      }
      @include breakpoint(lg) {
        flex: 1;
      }
    }
  }

  &--starts-mngmt {
    .c-filter-toggle-btn {
      display: none;
      @include breakpoint(sm) {
        display: block;
      }
    }
  }

  &.filter-header-input-list--assigned-accounts, &.filter-header-input-list--starts {
    @include breakpoint(xxl) {
      flex: initial;
      .filter-header-input {
        width: 300px;
      }
    }
  }
}

.filter-header-input {
  margin: 10px 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  @include breakpoint(sm) {
    flex: 1;
    &:not(:first-child) {
      margin-left: 18px;
    }
  }
  @include breakpoint(lg) {
    margin: 10px;
    max-width: 300px;

    &:not(:first-child) {
      margin-left: 10px;
    }

    &:first-child,
    &.sort-dropdown {
      margin-left: 0;
    }
  }
  .ant-select, .ant-select-selector {
    line-height: 38px;
    @include breakpoint(lg) {
      line-height: 1.5;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-picker.ant-picker-range {
    height: 44px;
    .c-sort-dropdown__placeholder {
      height: 100%;
    }
    @include breakpoint(lg) {
      height: 32px;
    }
  }
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
}

.filter-header-input-label {
  color: #000;
  font-family: Lato;
  font-size: 14px;
  margin-bottom: 8px;
  user-select: none;
  font-weight: bold;
  letter-spacing: 0.56px;
  line-height: 18px;
  &.hidden {
    display: none;
    color: transparent;
    @include breakpoint(lg) {
      display: block;
    }
  }
}

.filter-header-input-search {
  align-items: flex-end;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  @include breakpoint(sm) {
    width: 25%;
    // margin-top: 0;
    margin-right: 5%;
  }

  .c-filter-toggle-btn {
    margin-left: 5px;
    min-width: 150px;
    height: 35px;

    @include breakpoint(sm) {
      display: none;
    }
  }
}

.filter-header-input-custom {
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.filter-header-autocomplete .ant-input-affix-wrapper input.ant-input {
  // antd 4 overrides
  height: auto;
  border: none !important;
}

.filter-header-divisor {
  margin: 10px 20px;
  width: 1px;
  border: 1px solid #D8D8D8;
}

.filter-header-custom {
  &-list {
    display: flex;

    li {
      flex: 1;
      margin: 10px;
      width: 135px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-label {
    color: #4E4E4E;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.7px;
    line-height: 18px;
    margin-bottom: 8px;
  }
}

.filter-header-collapse {
  width: 100%;
  max-height: 0px;
  height: auto;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  background: transparent;
  @include breakpoint(sm) {
    background: #eeeeee;
  }
  // position: absolute;

  &.active {
    max-height: 100px;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
  }

  &.scrollable {
    overflow: auto;
  }

  .filter-header-input-list {
    flex-wrap: wrap;

    .filter-header-input {
      margin-left: 0;
      &:last-child {
        margin-right: 0;
      }
      @include breakpoint(sm) {
        flex: 30%;
        max-width: calc((100% - 30px) / 3);
        margin: 10px 10px 10px 0;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      @include breakpoint(lg) {
        flex: 20%;
        max-width: calc((100% - 30px) / 4);
        &:nth-child(3n) {
          margin-right: 10px;
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }
}

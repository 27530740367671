.c-toggle-tabs {
  &__btn {
    font-size: 14px;
    padding: 7px 12px;
    min-width: 98px;
    color: #8E8E8E;
    border: #D8D8D8 solid 1px;
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:not(:first-child) {
      border-left: none;
    }

    &.selected {
      background: var(--usergradientdark);
      color: $white;
      font-weight: bold;
    }
  }
}

.c-consultant-card {
  background: white;
  border-bottom: solid 0.625rem grey;
  border-radius: 0.1875rem;
  border-top: solid 0.625rem grey;
  box-shadow: 0 1px 17px 0 rgba(0,0,0,0.1);
  list-style-type: none;
  position: relative;
  width: 23.125rem;

  &--mngmt {
    border-top: none;
  }

  &--w-progress {
    border-bottom: none;
  }

  &--starts, &--deals {
    padding-top: 1.25rem;
    padding-bottom: 1.875rem;
  }

  &__main {
    padding: 0 1.875rem;
    
    &--mngmt {
      padding: 16px;
      @include breakpoint(sm) {
        padding: 1.875rem 1.875rem 2.5rem;
      }
    }
    
    &--top {
      padding-bottom: 1.25rem;
    }

    &--bottom {
      border-top: 1px solid #D8D8D8;
      padding-top: 1.375rem;
    }
  }

  &__progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &__progress-bar {
    width: 100%;
    height: 0.625rem;
    background: linear-gradient(270deg, #27D808 0%, #FFE337 33%, #FF8F1F 68.42%, #DC0707 100%);

    &--remaining {
      position: absolute;
      background: #E1E1E1;
      top: 0;
      right: 0;
    }
  }

  &__sub-date {
    background-color: #F6F6F6;
    font-size: 0.9375rem;
    font-weight: bold;
    height: fit-content;
    padding: 0.25rem 0.75rem;
    min-width: 5rem;
  }

  &__section {
    border-color: grey;

    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }

    &--w-divisor-after {
      &::after {
        flex-basis: 100%;
        border-bottom: solid 0.0625rem grey;
        border-color: inherit;
        content: '';
        display: block;
        margin-top: 1.25rem;
      }
    }
    &--w-divisor-before {
      &::before {
        flex-basis: 100%;
        border-top: solid 0.0625rem grey;
        border-color: inherit;
        content: '';
        display: block;
        margin-bottom: 1.25rem;
      }
    }
  }

  &__field {
    &--date {
      align-items: center;
      border-radius: 0.3125rem;
      border: 0.0625rem solid grey;
      display: flex;
      justify-content: space-between;
      padding: 0.625rem 1.125rem;
    }

    &--sub-date {
      margin-top: 0.625rem;
    }

    &--pointer {
      cursor: pointer;
    }
  }

  &__field-header {
    color: #8E8E8E;
    font-size: 0.875rem;
    letter-spacing: 0.035rem;
    margin-bottom: 0.3125rem;

    &--date {
      color: inherit;
      margin-bottom: 0;
    }

    &--sub-date {
      color: #6E6E6E;
      font-size: 0.625rem;
      font-weight: 500;
    }    
  }

  &__field-content {
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: 0.045rem;
    div {
      line-height: 22px;
    }

    &--sub-date {
      color: #1A1A1A;
      font-size: 0.75rem;
      font-weight: 500;
    }
  }

  &__document-bar {
    align-items: center;
    background-color: #F6F6F6;
    border-radius: 0.3125rem;
    display: flex;
    margin-bottom: 0.5rem;
    min-height: 45px;
    padding: 0.2rem 1rem;
  }

  &__doc-label {
    font-size: 1rem;
    margin-left: 12px;
    letter-spacing: 0.04rem;
  }

  &__document {
    align-items: center;
    display: flex;

    &--multi {
      flex: 0.5;
    }
  }

  &__info-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .c-consultant-card__field:not(:last-child) {
      margin-bottom: 0.9375rem;
    }

    &--top {
      flex-wrap: nowrap;
      flex: 1;
    }
  }

  &__candidate-block {
    display: flex;
    align-items: flex-start;

    &--mngmt {
      flex-direction: column;
      padding-left: 30px;
      .status-icon {
        position: absolute;
        left: 18px;
        padding: 0;
      }
      div {
        flex: 0.5;
      }
      @include breakpoint(sm) {
        padding-left: 0;
        flex-direction: row;
        .status-icon {
          position: initial;
          padding: 1px 6px;
        }
      }
    }
  }

  &__candidate-block-wrapper {
    flex: 1;
  }

  &__info-col {
    width: 50%;

    &:not(:first-child)  {
      padding-left: 1.1rem;
      border-left: 0.0625rem solid #D8D8D8;
    }

    .c-consultant-card__field:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }

  &__section-header {
    color: #1A1A1A;
    font-size: 1rem;
    margin-bottom: 1.875rem;
  }

  &__submission-status {
    margin-bottom: 1.125rem;
    
    > span {
      text-align: center;
      background:  #F6F6F6;
      border-radius: 0.3125rem;
      color: #4E4E4E;
      font-size: 0.8rem;
      font-weight: bold;
      padding: 0.3125rem 0.875rem;
      text-transform: uppercase;
    }

    &.approved {
      > span {
        background: #F1F8E9; 
        color: #4D9542;
      }
    }
  }
}
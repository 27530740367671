.c-sort-dropdown {
  display: flex;
  width: 200px;
  align-items: center;

  &__placeholder {
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  &__dir-box {
    border: solid $black 1px;
    border-radius: 4px;
    display: flex;
    margin-left: 8px;
    padding: 3px 6px;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }
  }

  .ant-select {
    flex: 1;
  }
}
$card-right-margin: 20px;

.c-brand-department-list {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 20px;
  width: 100%;

  &__group {
    max-width: 1240px;
    margin-bottom: 16px; // margin between groups should be a total of 40px
    width: 100%;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media screen and (max-width: 400px) {
      flex-wrap: wrap;
      justify-content: space-between;
      margin-right: 0px;
      .c-department-card {
        width: 48%;
        margin-right: 0px;
      }
    }
  }

  &__title {
    position: relative;
    color: #1a1a1a;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    align-items: center;
    display: flex;
    white-space: nowrap;
    margin-right: 20px;
    @media screen and (max-width: 400px) {
      margin-right: 0px;
    }
    &::after {
      background-color: #e6e6e6;
      content: '';
      height: 1px;
      margin-left: 10px;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 1334px) {
  .c-department-card {
    margin-right: 10px;
    margin-bottom: 12px;
  }
}

@media only screen and (min-width: 3840px) and (max-width: 4096px) {
  .c-brand-department-list__group {
    max-width: 3000px;
  }
  .c-brand-department-list__title {
    font-size: 18px;
  }
  .c-department-card {
    width: 275px;
    margin-right: 28px;
    margin-bottom: 28px;
    .c-department-card__inner {
      font-size: 23px;
    }
  }
  .logo-container {
    margin: 5% 0px;
  }
}

@media only screen and (max-width: 1334px) {
  .c-brand-department-list__group {
    max-width: 1060px;
  }
  .c-department-card {
    margin-right: 10px;
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 1134px) {
  .c-brand-department-list__group {
    max-width: 880px;
  }
}

@media only screen and (max-width: 954px) {
  .c-brand-department-list__group {
    max-width: 700px;
  }
}

@media only screen and (max-width: 774px) {
  .c-brand-department-list__group {
    max-width: 520px;
  }
}

@media only screen and (max-width: 594px) {
  .c-brand-department-list__group {
    max-width: 360px;
  }
}

@media only screen and (max-width: 320px) {
  .c-brand-department-list__group {
    max-width: 300px;
  }
}

.c-btn {
  padding: 1px 6px;
  &--bare {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
  }

  &--auto-cursor {
    cursor: auto;
  }

  &--pointer-cursor {
    cursor: pointer;
  }

  &--cta-1 {
    background: linear-gradient(270deg, #fb2f2f 0%, #d80808 100%);
    color: #ffffff;
    padding: 10px 22px;

    &:hover, &:disabled {
      opacity: 0.8;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }

  &--save {
    border-radius: 2px;
    background-color: #E00005;
    padding: 12px 43px;
    color: #FFFFFF;
    font: bold 12px/13px 'Lato';
    letter-spacing: 0;
    &:hover, &:disabled {
      opacity: 0.8;
    }
  }

  &--cancel {
    padding: 10px 22px;
    border: 1px solid #1A1A1A;
    border-radius: 2px;
    background-color: #FFFFFF;

    &:hover, &:disabled {
      opacity: 0.8;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }

  &--cancel-2 {
    border-radius: 2px;
    background-color: #E5E5E5;
    padding: 12px 43px;
    color: #000000;
    font: bold 12px/13px 'Lato';
    letter-spacing: 0;

    &:hover,
    &:disabled {
      opacity: 0.8;
    }
  }

  &--responsive {
    padding: 0;
    @media screen and (max-width: 1280px) {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 44px;
      min-height: 44px;
    }
  }
}

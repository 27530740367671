.c-sidebar {
  position: fixed;
  bottom: 0;
  height: calc(100% - 50px);
  box-shadow: 5px 0 10px 0 rgba(196, 197, 200, 0.4);
  z-index: 2;

  @include breakpoint(lg) {
    position: relative;
    height: 100%;
  }

  &__hide-collapsed {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &__items {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &--collapsed.c-sidebar {
    .c-sidebar {
      &__hide-collapsed {
        opacity: 0;
      }

      &__user-kebab-btn {
        left: 16px;
        bottom: -3px;
        top: unset;
        right: unset;
        transform: rotate(90deg);
      }
    }
  }

  &__link-menu {
    &.ant-menu-inline-collapsed {
      width: 60px;

      .ant-menu-item {
        padding: 0 20px !important;
      }
    }

    .ant-menu-item {
      align-items: center;
      display: flex;
      height: 60px;
      margin-bottom: 2px !important;
      margin-top: 0px !important;
      &::after {
        border-right: none;
      }
    }

    .ant-menu-item.ant-menu-item-selected {
      background: var(--usergradient);
      .c-sidebar {
        &__link-menu-item-icon {
          svg {
            path {
              fill: white;
            }
          }
        }

        &__link-menu-item-label {
          color: white;
        }
      }
    }
  }

  .ant-menu-item.ant-menu-item-active {
    background: #f3f3f3;
    &,
    &.ant-menu-item-selected .c-sidebar__link-menu-item-label {
      color: $sidbar-gray;
    }

    .c-sidebar__link-menu-item-icon {
      svg path {
        fill: var(--usercolor);
      }
    }
  }

  &__link-menu {
    border-right: none;
  }

  .c-sidebar &__link-menu-item {
    display: flex;
  }

  &__link-menu-item-icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    margin-right: 18px;
    width: 22px;
  }

  &__link-menu-item-label {
    color: $sidbar-gray;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__link-menu-item-tooltip {
    &::after {
      position: absolute;
      border-left: 1px solid #90949e;
      border-top: 1px solid #90949e;
      top: 5.5px;
      left: 26px;
      content: ' ';
      display: block;
      background: #f3f3f3;
      width: 5px;
      height: 5px;
      transform: rotate(45deg);
    }
    .ant-tooltip-content {
      .ant-tooltip-arrow .ant-tooltip-arrow-content {
        display: none;
        background: #f3f3f3;
        border: 1px solid #90949e;
      }
      .ant-tooltip-inner {
        font-family: Lato;
        padding: 2px 14px;
        font-size: 12px;
        min-height: 15px;
        background: #f3f3f3;
        border: 1px solid #90949e;
        color: $sidbar-gray;
      }
    }
  }

  &__expand-btn {
    background: white;
    border-radius: 50%;
    border: none;
    color: $sidbar-gray;
    top: 70px;
    box-shadow: 0 0 0 1px rgba(9, 30, 66, 0.08),
      0 2px 4px 1px rgba(9, 30, 66, 0.08);
    font-size: 20px;
    height: 28px;
    line-height: 0;
    opacity: 1;
    position: absolute;
    right: -12px;
    transition: opacity 0.3s;
    user-select: none;
    width: 28px;

    &:hover {
      border: solid 1px var(--usercolor);
      color: var(--usercolor);
    }
  }

  &__user-menu-item {
    position: relative;
    white-space: nowrap;
    display: flex;
    padding: 18px;
    margin-bottom: 30px;
  }

  &__user-menu-name {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
  }

  &__user-menu-department {
    color: $sidbar-gray;
    font-size: 13px;
    line-height: 16px;
  }

  &__user-kebab-btn {
    position: absolute;
    right: 14px;
    top: 25px;
    width: 28px;
    height: 28px;
    background: transparent;
    border: none;

    &.ant-dropdown-open,
    &:hover {
      background: #f3f3f3;
    }
  }

  &__user-kebab-menu-item {
    padding: 5px 20px;
    cursor: pointer;

    &,
    & a {
      display: flex;
      align-items: center;
      width: 175px;
      color: $sidbar-gray;

      &:hover {
        font-weight: bold;

        svg path {
          fill: var(--usercolor);
        }
      }

      svg {
        width: 15px;
        margin-right: 7px;
      }
    }
  }
}

.color-picker-popover {
  z-index: 160;
  padding: 0;
  margin: 0;
  background: transparent !important;
  box-shadow: unset !important;
}
.settings-wrapper {
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.7rem;
  padding: 0 0.375rem 0.375rem 0.375rem;
  z-index: 170;
  pointer-events: all;
  max-height: 90vh;
  overflow: auto;
  width: calc(100% - 24px);

  @include breakpoint(sm) {
    width: 576px;
  }

  .settings-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.6rem;
    width: 100%;

    button {
      color: white;
      background: none;
      border: none;
    }
  }

  h2 {
    color: $white;
    font-weight: 600;
    margin: 0;
    font-size: 1.5rem;
    letter-spacing: 0.06rem;
  }

  h3 {
    font-family: Lato;
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: 0.045rem;
  }

  .bottom-content {
    background-color: $white;
    width: 100%;
    border-radius: 0.7rem;
    padding: 1.25rem 0;
    .block {
      padding: 0 1.375rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: $black;
      // border-bottom: 0.1rem solid grey;
      text-transform: none;
      &.noborder {
        border: none;
      }

      &:not(:last-child) {
        padding-bottom: 1.25rem;
      }

      &:not(:last-child):after {
        content: '';
        display: block;
        flex-basis: 100%;
        margin-top: 0.9rem;;
        height: 0.1rem;
        background: #4E4E4E;
      }
    }
    .profile-picture {

      h3 {
        color: $black;
        text-transform: none;
      }
      &.block {
        display: flex;
        justify-content: space-between;
      }
      .avatar-upload {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        input[type='file'] {
          display: none;
        }
        img {
          object-fit: cover;
          max-width: 3.75rem;
          max-height: 3.75rem;
          border-radius: 0.7rem;
          margin-bottom: 0.625rem;
        }
      }
    }
    .profile-color {
      h3 {
        color: $black;
        text-transform: none;
      }
      .profile-color {
        cursor: pointer;
        width: 3.75rem;
        height: 3.75rem;
        border-radius: 0.3125rem;
      }
    }
    .picker-wrapper {
      text-align: center;
      margin-top: 1.5rem;
      border-bottom: 0.1rem solid grey;
      padding-bottom: 1.5rem;
      display: flex;
      background-color: red;
      justify-content: center;
      .hue-picker {
        div {
          div {
            div {
              top: 20%;
              transform: scale(2);
            }
          }
        }
      }
    }
    .profile-goal {

      &--monthly {
        padding: 0;

        h3 {
          padding-left: 1.375rem;
        }

        &:after {
          margin: 0 auto;
          flex-basis: 92% !important;
        }
      }

      h3 {
        color: $black;
        text-transform: none;
        margin-bottom: 0.8125rem;
      }
      .goal-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-basis: 100%;
        background-color: #F6F6F6;
        height: 3.125rem;
        padding: 0 2rem;
        margin-bottom: 0.625rem;

        &__label {
          color: #4E4E4E;
          font-family: Lato;
          font-size: 1.125rem;
          letter-spacing: 0.72px;
        }
      }
      .goal-select {
        height: 1.875rem;
        width: 5rem;
        overflow: hidden;
        display: flex;
        // box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        .decrease {
          color: #fff;
          height: 100%;
          width: 33%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.375rem;
          font-weight: 600;
        }
        .number {
          height: 100%;
          width: 70%;
          color: $black;
          text-transform: none;
          // line-height: 4rem;
          font-size:1.125rem;
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          border: 0.1rem solid #E8E8E8;
          // border-top: 0.1rem solid lightgrey;
          // border-bottom: 0.1rem solid lightgrey;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        .increase {
          height: 100%;
          width: 30%;
          border-top-right-radius: 1rem;
          border-bottom-right-radius: 1rem;
          position: relative;
          color: #fff;
        }
      }
    }
    .block:last-child {
      border-bottom: none;
    }
  }
}

.conferenceline-popover {
  padding-bottom: 1px;
  text-align: center;
  h2 {
    color: white;
    font-size: 18px;
    margin: 0;
    padding: 0.5rem 0;
    text-align: center;
  }
  .bottom-section {
    margin: 0;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    flex-direction: column;
    .goal-select {
      margin: 1.5rem;
      height: 70px;
      width: 200px;
      overflow: hidden;
      display: flex;
      border-radius: 10px;
      box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
      .decrease {
        color: #fff;
        height: 100%;
        width: 33%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 2rem;
      }
      .number {
        z-index: 9999;
        height: 100%;
        width: 70%;
        color: $black;
        text-transform: none;
        // line-height: 4rem;
        font-size: 2rem;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-top: 0.1rem solid lightgrey;
        border-bottom: 0.1rem solid lightgrey;
      }
    }
  }
}

.c-conference-line-modal {
  .link-form {
      &-item {
        position: relative;
        margin-bottom: 10px;
        text-align: left;
        label {
          font-weight: 600;
        }

        &:last-child {
          padding-bottom: 20px;
        }
      }

      .c-char-warning {
        position: absolute;
        bottom: 0;
        right: 5px;
      }

      &__input {
        position: relative;
        &--prefix {
          .prefix-icon {
            position: absolute;
            left: 0;
            width: 20px;
            z-index: 2;
            color: #fff;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.375rem;
            font-weight: 600;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          input {
            padding-left: 25px;
          }
        }
      }
    }
    .toggle {
      align-items: center;
      display: flex;
      font-size: 14px;
      font-weight: 600;
      justify-content: flex-end;
      padding: 5px;
      width: 100%;
      span {
        margin-right: 4px;
      }
    }
}

.rc-select {
  width: 200px;
  // position: relative;
}

.rc-select-dropdown {
  &.rc-select-dropdown--single {
    z-index: 9999 !important;
    li {
      // z-index: 20000 !important;
    }
  }
}
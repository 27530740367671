.c-ts-menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 48px 64px;
  background-color: $white;
  align-self: flex-end;
  z-index: 1;

  &.is-open {
    align-items: flex-end;
    box-shadow: inset 0 1px 10px 0 rgba(0,0,0,0.25);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__board-selector {
    list-style: none;
    display: flex;
    flex: 1;

    &__item {
      display: flex;
      align-items: center;
      appearance: none;
      border: none;
      width: 80px;
      max-width: 80px;
      height: 10px;
      background-color: #ccc;
      margin: 0 12px 0 0;
      padding: 0;
      border-radius: 0;

      &.is-active {
        background-color: $red;

        &.is-open {
          color: $white;
          background: linear-gradient(226.64deg, #FF5353 0%, #D80808 100%);

          svg {
            fill: $white;
          }
        }
      }

      &.is-open {
        padding: 24px 40px;
        height: auto;
        max-width: 100%;
        width: auto;
        text-transform: uppercase;
        background-color: #fff;
        border: solid 2px $red;
        font-size: 28px;
        font-weight: bold;
        color: #4E4E4E;
      }

      svg {
        font-size: 28px;
        fill: #4E4E4E;
        margin-right: 20px;
        vertical-align: middle;
      }
    }
  }

  &__button {
    svg {
      font-size: 64px;
      fill: $red
    }
  }

  &__divisions {
    flex: 1;
    display: flex;

    &_btn {
      margin: 0 0 0 auto;
      background-color: #fff;
      padding: 27px 54px;
      border: 0.2px solid #666666;
      border-radius: 6px;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: bold;
      line-height: 1em;
      min-width: 375px;

      svg {
        font-size: 28px;
        fill: $red;
        margin-right: 20px;
        vertical-align: top;
      }
    }
  }
}

.main-header-container {
  z-index: 1;
}

.main-header {
  // box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
  width: 100%;
  background-color: #f8f8f8;
  @include breakpoint(lg) {
    border-bottom: 1px solid rgba(216, 216, 216, 0.3);
  }
  @include breakpoint(xxl) {
    margin: 0 auto;
    max-width: 3402px; // 3236px width for content while considering the 83px padding on both sides
  }
  .list {
    height: 80px;
    padding: 0 83px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .list-element {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      color: #000 !important;
    }
  }

  .header-user-card {
    max-width: none;

    .department-card.user {
      margin-left: 0;
    }
  }

  &__text {
    user-select: none;
    color: #000000;
    width: 100%;
    height: 100%;
    justify-content: center;
    text-transform: uppercase;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 24px;
    display: flex;
    align-items: center;

    &--simple {
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.96px;
      line-height: 29px;
      text-transform: capitalize;
    }
  }

  .c-plain-header {
    display: none;
    @include breakpoint(lg) {
      display: flex;
    }
  }
}

.no-transition {
  transition: none !important;
}
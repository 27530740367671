.c-sendout-mgt {
  > div {
    > div {
      margin-bottom: 16px;
    }

    @include breakpoint(sm){
      display: flex;
      > div {
        flex-basis: 50%;
        &:first-child {
          margin-right: 24px;
        }
      }
    }
    @include breakpoint(lg) {
      align-items: stretch;
      margin: 0 -10px 20px -10px;
      > div {
        flex-basis: auto;
        margin: 0 10px;
      }
    }
  }

  &__scheduled {
    flex: 0.58;
    > div:first-child {
      margin-bottom: 20px;
    }
  }

  &__opentimes {
    flex: 0.42;
    padding: 13px 19px;
    &__sendouts {
      margin: 18px 0;
    }
  }

  &__opentimes-data-cols {
    display: flex;

    > div, ul {
      flex: 0.5;

      > li {
        display: flex;
        align-items: center;
      }
    }

    .g-sendouts__number {
      margin-right: 6px;
    }
  }

  &__confirmed {
    flex: 0.58;
    padding: 13px 19px;
    &__sendouts {
      position: relative;
      margin: 12px 0 10px;
      display: flex;
      align-items: center;
    }
    &__chart-text {
      position: absolute;
      top: 72px;
      left: 77px;
      transform: translate(-50%, -50%);
    }
    &__data, &__chart-text {
      text-align: center;
      > div:first-child {
        text-align: center;
        font-size: 30px;
        line-height: 40px;
      }
      > div:last-child {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: .75px;
        color: #696969;
      }
    }
    &__right {
      flex: 1;
      display: flex;
      > div {
        flex: 1;
        height: 76px;
        padding-top: 7px;
      }
      > div:first-child {
        border-right: 1px solid #E6E6E6;
      }
    }

    .g-column-sendouts {
      flex: 1;
    }
  }
  
  &__open {
    flex: 0.42;
    padding: 13px 19px;
    &__sendouts {
      display: flex;
      margin-top: 16px;
      @include breakpoint(lg) {
        display: block;

        .c-dealscard__icon {
          display: none;
        }
      }
      &__list {
        flex: 1;
        @include breakpoint(lg) {
          flex: auto;
        }
      }
    }
  }

  &__number-cards {
    @include breakpoint(lg) {      
      display: flex;
      > div {
        flex: 1;
      }
    }
  }

  &__icon, .c-dealscard__icon {
    height: 126px;
    margin-right: 2.2rem;
    width: 126px;
  }
}

.c-submittal {
  @include breakpoint(sm) {    
    > div {
      display: flex;
      margin: 0 -10px 20px -10px;
      > div {
        margin: 0 10px;
        width: 50%;
      }
  }
  }
  &__numbers {
    > div {
      flex: 1;
      margin-bottom: 16px;
      @include breakpoint(lg) {
        margin-bottom: 0;
      }
    }
  }
  &__mgt {
    padding-top: 16px;
    &__title {
      padding: 13px 0 0 19px;
    }
  }
}

.c-jobsmgt {
  flex: 0.6;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  @include breakpoint(lg) {
    margin-bottom: 0;
  }
  &__card {
    flex: 1;
  }
  &__content {
    padding: 15px 19px;
  }
  &__chart {
    margin: 25px 0 0 -29px;
  }
}

.c-leadsmgt {
  flex: 0.4;
  &__types {
    padding: 0 19px;
    > :last-child {
      padding-bottom: 20px;
    }
  }
}

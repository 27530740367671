#starts-board {
  .header-container {
    display: flex;
    justify-content: space-between;
  }

  &.headerless {
    // overflow: visible;
    .header {
      position: initial;
      width: auto;
    }
  }
  .element-container, .element-container-fixed {
    display: flex;
    width: 100%;
    .inner-container {
      width: 100%;
      padding: 10px 40px;
      display: flex;
      margin-bottom: 20px;
      border-radius: 3px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
      .cell {
        @extend %font-16;
        color: inherit;
        display: flex;
        align-items: center;
        height: 100%;
        .menu {
          &:hover {
            &::after {
              font-family: 'Font Awesome 5 Free';
              font-weight: 300;
              content: '\f2c2';
              position: absolute;
              // right: -5px;
              // top: 8px;
              font-size: 16px;
              // margin-left: 7px;
              color: #173261;
              transform: translateX(10px);
            }
          }
        }
      }
    }
  }
  .header {
    position: fixed;
    top: 80px;
    width: 100%;
    z-index: 3;
    padding-bottom: 0;
    padding-bottom: 15px;
    .row-container {
      margin-bottom: 0px;
      .inner-container {
        background-color: #F8F8F8;
        height: 20px;
        box-shadow: none;
        .cell {
          @extend %font-16;
          color: #222222;
          font-weight: bold;
        }
      }
    }
  }
}
.status-icon {
  // width: 50px;
  // vertical-align: middle;
  // display: inline-block;
  // height: 35px;
  // text-align: center;

  .status {
    vertical-align: bottom;
    margin-right: 1.5rem;
    &.-circled {
      &::before {
        left: -0.5rem;
      }
    }
  }
}

.c-starts-board {

  &__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include breakpoint(lg) {
      justify-content: space-evenly;
    }

    &.hidden {
      height: 0;
      overflow: hidden;
    }

    .c-consultant-card {
      margin-right: 0.5%;
      margin-left: 0.5%;
      margin-bottom: 0.9375rem;
      width: 100%;

      @include breakpoint(sm) {
        width: 48%;
      }
      @include breakpoint(lg) {
        width: 23.125rem;
      }
    }
  }
}

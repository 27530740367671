.hidden-records-dialog {
  overflow: hidden;
  padding: 0.4rem;

  table {
    background-color: $white;
    border-radius: 5px;
    width: 100%;

    thead {
      tr {
        border-bottom: 1px solid $light-gray;
        display: flex;

        th {
          font-size: 1.4rem;
          text-transform: uppercase;
          text-align: left;
          padding: 1.2rem;
          font-weight: bold;

          &.row-header {
            font-size: 14px;
            text-transform: uppercase;
            text-align: left;
            padding: 0.7rem 1rem;
            font-weight: 700;
          }
        }
      }
    }

    tbody {
      max-height: 40vh;
      overflow: auto;
      display: block;

      tr {
        border-bottom: 1px solid $light-gray;
        display: flex;

        &:last-child {
          border: none;
        }

        td {
          padding: 1.5rem 1.2rem;

          span {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

.hidden-records-title {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin: 11px;

    @include breakpoint(lg) {
      font-size: 30px;
      padding-bottom: .8rem;
      margin: 22px;
    }
}

.hidden-records-subtitle {
  margin: 1rem 2.5rem;
  width: 90%;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin: 11px;
  text-transform: uppercase;
  @include breakpoint(lg) {
    font-size: 20px;
    margin: 10px 22px 22px 22px;
  }
}
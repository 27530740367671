.c-progress-bar-chart {
  .g-progressbar {
    display: flex;
    align-items: center;

    &__text {
      color: #340F0D;
      font-family: Lato;
      font-size: 16px;
      font-weight: 900;

      &--goal-reached {
        left: 2px;
        position: absolute;
        text-align: right;
        top: 7px;
        width: 100%;
        z-index: 1;
      }
    }

    &__goal {
      font-weight: 500;

      &--w-spacing {
        margin-left: 0.5rem;
      }
    }

    &__align-text-end {
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }

    &__pattern-overlay {
      left: 2px;
      opacity: 0.4;
      position: absolute;
      top: 7px;
      z-index: 1;
    }
  }

  &__row {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__left-col {
    position: relative;
    flex: 0.74;
  }

  &__right-col {
    align-items: center;
    display: flex;
    flex: 0.24;
    padding-left: 12px;
  }

  &__label {
    color: #1A1A1A;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0.2px;
  }
}
@import '../scrollbar';

.popover-background {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity 0.35s ease;
  z-index: 1000;
  pointer-events: none;
}

.default-popover {
  position: relative;
  margin-top: 2rem;

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 1.5rem solid transparent;
    border-right: 1.5rem solid transparent;
    border-bottom: 1.5rem solid $white;
    position: absolute;
    top: -1.3rem;
    left: 1.5rem;
  }
}

.info-popover {
  position: relative;
  margin-top: 2rem;

  >div {
    overflow: initial !important;
  }

  div[role=menu] {
    padding: 0 !important;
  }

  .popover-arrow {
    width: 0;
    height: 0;
    border-left: 1.5rem solid transparent;
    border-right: 1.5rem solid transparent;
    border-bottom: 1.5rem solid $white;
    position: absolute;
    top: -1.5rem;
    left: 1.5rem;
  }
}


.popover {
  display: flex;
  justify-content: space-around;
  > * {
    @include scrollbar(.6em, rgba(0,0,0,0.65));
  }
  &.wide {
    .popover-block {
      width: 100%;
      h2 {
        line-height: initial;
        margin: 10px 20px;
        color: white;
      }
      .list-items {
        overflow: auto !important;
        @include breakpoint(sm) {
          min-width: 400px;
        }
      }
    }
  }

  .popover-block {
    width: 50%;
    margin-right: 1rem;
    @include scrollbar(.6em, rgba(0,0,0,0.65));

    &:last-child {
      margin-right: 0;
    }

    h2 {
      color: #fff;
      @extend %font-16;
      width: 70%;
      margin: 0.5rem 1.5rem;
    }
    .bottom-info {
      background: #fff;
      border-radius: 0 0 0.5rem 0.5rem;
      h2 {
        padding-left: 20px;
        padding-bottom: 5px;
        margin: 0;
        color: #4A4A4A;
        font-family: Lato;
        font-size: 14px;
        letter-spacing: 0.56px;
        line-height: 24px;
      }
    }
    .list-items {
      background: white;
      margin: 0;
      max-height: 300px;
      overflow: auto;
      padding: 10px 5%;
      &:not(:last-child){
          &:after {
              content : "";
              position: absolute;
              width: 90%;
              left: 5%;
              border-bottom:1px solid $light-gray;
          }
      }
      &:last-child {
        border-radius: 0 0 0.5rem 0.5rem;
      }
      li {
        background: $white;
        .item-label {
          @extend %font-16;
          display: inline-block;
          width: 75%;
          padding: 0.5rem 0;
          vertical-align: top;

          &.left {
            font-size: 21px;
            padding: 0;
          }
        }
        .item-value {
          width: 25%;
          padding: 0.25rem 0;
          color: #000;
          font-size: 1.3rem;
          display: inline-block;
          vertical-align: top;
          text-align: right;
        }
        .item-children {
          // padding-left: 0rem;
          ul {
            padding: 1rem 0rem;
            border-bottom-right-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;

            li {
              @extend %font-16;
              border: none;
              margin-bottom: 0.3rem;
              color: grey;
            }
          }
        }
      }
      li:first-child {
        .item-value {
          border-top-right-radius: 0.5rem;
        }
        .item-label {
          border-top-left-radius: 0.5rem;
        }
      }
      li:last-child {
        border-bottom: none;
        .item-value {
          border-top-right-radius: 0rem;
          border-bottom-right-radius: 0.5rem;
          &.has-children {
            font-size: 20px;
            border-bottom-right-radius: 0rem;
          }
        }
        .item-label {
          border-top-left-radius: 0rem;
          border-bottom-left-radius: 0.5rem;
          &.has-children {
            font-size: 20px;
            border-bottom-left-radius: 0rem;
          }
        }
      }
      // if there is only 1 li.. this takes over
      li:only-child {
        .item-value {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
        .item-label {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }
      }
    }
  }
}
.candidate-history {
  div[role='presentation'] {
    width: auto !important;
  }
  .item-label {
    font-family: 'Lato';
    font-weight: 400;
  }
}

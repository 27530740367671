.c-recent-deals {
  width: 100%;
  min-height: 8.25rem;
  margin-bottom: 30px;
  // perspective: 1000px;
  &--single.c-recent-deals--header {
    @include breakpoint(xxl) {
      display: none;
    }
  }

  &--list {
    display: none;
    &.c-recent-deals.c-recent-deals--header {
      @include breakpoint(xxl) {
        display: flex;
      }
    }

    .c-rd-card {
      &__section--counter {
        display: none;
      }
      margin: 0 12px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.c-recent-deals--single .c-rd-card {
  @include breakpoint(lg) {
    padding-left: 0;
    padding-right: 0;
    height: 9rem;
  }

  &__info {
    @include breakpoint(lg) {
      justify-content: space-between;
      flex-wrap: nowrap;
    }

    .c-rd-card__section {
      &.date.added {
        @include breakpoint(lg) {
          flex-basis: auto;
        }
      }

      &.division,
      &.company.name,
      &.spread,
      &.margin {
        @include breakpoint(sm) {
          flex-basis: 25%;
        }

        @include breakpoint(lg) {
          flex-basis: auto;
        }
      }

      &.account.manager,
      &.recruiter {
        @include breakpoint(lg) {
          flex-basis: auto;
        }
      }
    }
  }

  &__section {
    &--border-left {
      @include breakpoint(lg) {
        border-left: 1px solid #E6E6E6;
      }
    }

    &--counter {
      &.mobile {
        @include breakpoint(lg) {
          display: none;
        }
      }

      &.desktop {
        @include breakpoint(lg) {
          display: flex;
        }
      }
    }
  }
}


.c-rd-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: auto;
  padding: 20px 8px 25px;
  width: 100%;
  
  &__checkmark {
    background-image: url(../../assets/alku-check.svg);
    display: inline-block;
    height: 11px;
    margin-right: 4px;
    width: 11px;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .c-rd-card__section {
      flex-basis: 50%;
      &.date.added {
        flex-basis: 100%;
      }
    }
  }

  &__section-label {
    color: #696969;
    font-size: 12px;
    letter-spacing: 0.75px;
    line-height: 14px;
    margin-bottom: 4px;

    &--counter {
      color: #1A1A1A;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 18px;
    }
  }

  &__section-content {
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.22px;
    line-height: 22px;
  }

  &__section {
    padding: 5px 1.5%;

    &--counter {
      display: flex;
      justify-content: space-between;
      flex-basis: 100%;
      &.mobile {
        padding: 0 0 14px;
      }
      &.desktop {
        display: none;
      }
    }
  }

  &__ticker {
    display: inline-block;
    height: 4px;
    width: 18px;
    border-radius: 2px;
    background-color: #D8D8D8;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &--active {
      background-color: #4E4E4E;;
    }
  }
}

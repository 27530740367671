.c-tooltip {
  .ant-tooltip-inner {
    background: $white;
    color: $black;
  }

  .ant-tooltip-arrow:before {
    background: $white;
  }

  &--amr {
    .ant-tooltip-inner {
      border-radius: 8px;
      line-height: 18px;
      padding: 12px 16px;
      text-align: center;
    }
  }
  &--toptab {
    .ant-tooltip-inner {
      max-height: 300px;
      overflow: auto;
      border-radius: 8px;
      line-height: 18px;
      padding: 12px 16px;

      &::-webkit-scrollbar {
        width: 20px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #CCCCCC;
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #a8bbbf;
      }
    }
  }
  &--multi-division {
    li {
      margin-bottom: 5px;
    }
    .c-md-tooltip__text, .c-md-tooltip__department {
      font: 'Lato' 15px;
      color: white;
    }
    .c-md-tooltip__department {
      font-weight: bold;
    }
    .c-md-tooltip__employee {
      font: 'Lato' 14px;
      color: #CCCCCC;
    }
  }
}

.dev-console-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  min-width: 15%;
  padding: 1%;
  border-radius: 5px;
  background-color: rgba(0,0,0,0.8);
  z-index: 9999;

  h2 {
    margin: 0;
    color: white;
  }
}

.dev-console-socket-text-container {
  display: flex;
  padding-bottom: 5px;
}

.dev-console-socket-emoji {
  padding-right: 10px;
}
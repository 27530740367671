$active: #FF5353;

.c-pidb {
  padding: 75px 32px;

  &__header {
    position: relative;
    color: #000000;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.96px;
    line-height: 26px;
    margin-bottom: 15px;

    &::after {
      content: ' ';
      display: block;
      height: 1px;
      width: calc(100vw - 150px);
      opacity: 0.3;
      margin-top: 24px;
      background-color: #D8D8D8;
    }
  }

  &__action-bar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 32px;

    .filter-header-input {
      margin: 0 10px 0 0;
      .filter-header-input-label {
        display: none;
      }
    }
    
    .ant-select .ant-select-selector .ant-select-selection-placeholder, 
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    .c-btn--cta-1.c-btn,
    .ant-select .ant-select-selector,
    .ant-select .ant-select-selector input.ant-select-selection-search-input,
    .ant-select .ant-select-selector .ant-select-selection-placeholder
    {
      height: 45px;
    }
  }

  &__table {
    &.ant-table {
      background: transparent;
    }

    &.ant-table-column-sorters {
      justify-content: flex-start;
    }

    &.ant-table-cell {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px;
    }

    &.ant-table-column-title {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.56px;
      line-height: 18px;
    }

    &.ant-table-thead>tr>th:before {
      background-color: transparent !important;
    }

    &.ant-table-column-sorter-inner {
      .ant-table-column-sorter-up, .ant-table-column-sorter-down {
        color: #000000;
        &.active {
          color: $active;
        }
      }
    }
  }

  &__action-cell {
    display: flex;

    .c-btn:hover {
      svg path {
        fill: $active;
      }
    }
  }

  &__table-pagination.ant-table-pagination {
    li.ant-pagination-disabled {
      .c-pidb__pagination-arrow {
        svg path {
          fill: #AAA;
        }
      }
    }
    li.ant-pagination-item {
        font-size: 16px;
        font-family: Lato;
        background-color: transparent;
        border: none;
        font-weight: normal;
        a {
          font-weight: 500;
          color: #4E4E4E;

          &:hover {
            color: $active;
          }
        }
  
      &.ant-pagination-item-active {
        border: 2px solid $active;

        a {
          font-weight: 900;
          color: #1A1A1A;
        }
      }
    }
  }

  &__pagination-arrow {
    height: 100%;
  }

  &__remove-dialog, &__details-modal-footer {
    .c-btn--cancel, .c-btn--cta-1.c-btn {
      font-size: 12px;
      font-weight: bold;
      height: 40px;
      min-width: 100px;
      &:first-child {
        margin-right: 18px;
      }
    }
  }

  &__details-modal {
    .ant-modal-body {
      padding: 32px;
    }

    &-header {
      color: #1A1A1A;
      font-size: 18px;
      font-weight: bold;
    }

    &-body {
      .filter-header-input {
        margin-left: 0;

        &.wide {
          max-width: 466px;
        }

        & .ant-input-affix-wrapper input.ant-input {
          border: none !important;
        }
      }
      .filter-header-input-label {
        color: #1A1A1A;
        font-size: 15px;
        font-weight: 500;
      }
    }

    &-footer {
      margin-top: 30px;
    }
  }

  &__error-banner {
    background-color: #FBE5E6;
    box-shadow: 0 1px 1px 0 rgba(167, 112, 112, 0.5);
    padding: 16px 11px;
    width: 100%;

    svg {
      margin-right: 10px;
    }
  }

  &__input-error {
    align-items: center;
    color: #CD0005;
    display: flex;
    font-size: 14px;
    font-weight: bold;

    svg {
      margin-right: 7px;
    }
  }
}

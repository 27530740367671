.c-board-selector {
  list-style: none;
  display: flex;
  position: absolute;
  left: 6.4rem;
  bottom: 8.5rem;

  &__item {
    appearance: none;
    border: none;
    width: 8rem;
    max-width: 8rem;
    height: 1rem;
    background-color: #ccc;
    margin: 0 1.2rem 0 0;
    padding: 0;
    border-radius: 0;

    &.is-active {
      background-color: $red;
    }
  }
}

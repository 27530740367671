@import './utils/module';
@import 'vars';
@import 'base';

// vendor
@import './rc-select.scss';
@import '../../../node_modules/react-toastify/dist/ReactToastify.css';

// shared components
@import './shared/button.scss';
@import './shared/menu.scss';

// components
@import './components/header';
@import './components/mainHeader';
@import './components/filterHeader';
@import './components/navigation';
@import './components/mainContent';
@import './components/assignedAccounts';
@import './components/tables';
@import './components/devConsole';
@import './components/popover';
@import './components/userCard';
@import './components/modal';
@import './boards/activity';
@import './components/userSettings';
@import './components/HiddenRecords';
@import './components/spinner';
@import './components/toggleBtn.scss';
@import './components/toggleTabs.scss';
@import './components/startsToggle.scss';
@import './components/consultantCard.scss';
@import './components/startsModal.scss';
@import './components/categoryFilter';
@import './components/progressBarChart';
@import './components/recentDeals';
@import './components/onboardingPaperwork';
@import './components/simpleSearch';
@import './components/filterToggleBtn';
@import './components/notice';
@import './components/sortDropdown';
@import './components/tooltip';
@import './components/charWarning.scss';
@import './components/departmentSelection.scss';
@import './components/departmentCard.scss';
@import './components/brandDepartmentList.scss';
@import './components/link.scss';
@import './components/sidebar.scss';
@import './components/skeleton.scss';
@import './components/mobileHeader.scss';
@import './components/input.scss';
@import './components/wysiwyg.scss';
@import './components/tsHeader.scss';
@import './components/boardSelector.scss';
@import './components/input.scss';
@import './components/tsMenu.scss';
@import './components/tsMain.scss';
@import './components/drawer.scss';

// boards
@import './boards/deals';
@import './boards/userSelection';
@import './boards/jobs';
@import './boards/starts';
@import './boards/dashboard';
@import './boards/startsMngmt';
@import './boards/pidb.scss';

// animations
@import './animations';

// scrollbar
@import './scrollbar';

// antd overrides
@import './antdForceOverrides.scss';
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  transition: opacity 0.35s ease;
  z-index: 1000;
  pointer-events: none;
  @include breakpoint(lg) {
    width: 100vw;
    height: 100vh;
  }

  .modal-background {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.35s ease;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }

  .modal-inner {
    position: absolute;
    top: 15%;
    left: 0;
    right: 0;
    background-color: #fff;
    margin: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    border-radius: 0.7rem;
    padding: 0.5rem;
    z-index: 150;
    pointer-events: all;

    @include breakpoint(sm) {
      width: 500px;
    }

    h2 {
      color: $black;
      font-weight: 600;
      line-height: 3rem;
      margin: 1rem 2.5rem;
    }
    h3 {
      color: $black;
      margin: 1rem 2.5rem;
    }
  }
}

.c-modal {
  .ant-modal-content {
    box-shadow: 0 8px 24px 0 rgba(26, 26, 26, 0.3);
  }
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-close {
    top: 16px;
    right: 16px;
    .ant-modal-close-x {
      color: white;
      font-size: 18px;
      line-height: 18px;
      height: 20px;
      width: 20px;
    }
  }

  &--rate-fields {
    max-width: 472px;
  }

  &--interview-prep-details {
    max-width: 540px;

    .c-modal__body {
      display: flex;
      flex-direction: column;
      padding: 24px 28px;
      height: 350px;

      .quill {
        height: 185px;
      }
    }
    .c-modal__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 440px) {
        flex-direction: column-reverse;
        align-items: flex-end;
      }
    }
  }

  &__header {
    background: linear-gradient(0deg, #191919 0%, #7C7C7C 100%);
    color: #FFFFFF;
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    padding: 14px 32px;
  }

  &__title {
    color: #1A1A1A;
    font: bold 16px/24px 'Lato';
    letter-spacing: 0;
  }

  &__title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .c-btn--edit-notes {
      display: flex;
      align-items: center;
      color: #3074D2;
      font: bold 16px/17px 'Lato';
      letter-spacing: 0.2px;
      width: 121px;

      &.hidden {
        visibility: hidden;
      }
      
      svg {
        margin-left: 8px;
      }
    }
  }

  &__body {
    padding: 20px 40px 32px;
  }
  &__list-item {
    &:not(:last-child) {
      margin-bottom: 18px;
    }
    &--open-times {
      color: #1A1A1A;
      font-family: Lato;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 32px;
    }
  }
  &__list-item-title {
    color: #1A1A1A;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 10px;
  }
  &__footer {
    padding: 12px 12px 16px;
    border-top: solid 1px #D8D8D8;

    &.hidden {
      display: none;
    }

    .c-btn--delete-note {
      display: flex;
      align-items: center;
      height: 40px;
      color: #4E4E4E;
      font: bold 16px/17px 'Lato';
      letter-spacing: 0.2px;

      &.hidden {
        visibility: hidden;
      }
    }
  } 
  &__actions {
    display: flex;
    justify-content: flex-end;

    .c-btn {
      &:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
}
.c-menu {
  &__popover-pointer {
    cursor: pointer;
    position: relative;
    &:hover::after {
      font-family: 'Font Awesome 5 Free';
      font-weight: 300;
      content: '\f2c2';
      position: absolute;
      // top: 8px;
      right: -15px;
      font-size: 16px;
      color: #173261;
      transform: translateX(10px);
      z-index: 20;
    }
  }
}
.c-ts-header {
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 84px;
  width: 100%;
  padding: 21px 64px;

  &__separator {
    width: 1px;
    height: 42px;
    background-color: #777;
    margin: 0 22px;
  }

  span {
    color: #fff;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    margin: 0 16px;
  }
}

.c-spinner-container {
  width: 100%;
  height: 100%;
  background-color: #5660632a;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  &--flex {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
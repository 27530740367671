.c-starts-modal {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  max-width: calc(100vw - 36px);
  overflow: hidden;
  @include breakpoint(sm) {
    min-width: 580px;
  }

  .ant-modal-body {
    padding: 0px;
  }

  &__body {
    overflow: auto;
    height: 100%;
    padding: 40px;
  }

  .ant-modal-close-x {
    color: white;
    font-size: 20px;
    width: 90px;
  }

  &__header {
    background: inherit;
    color: white;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.72px;
    line-height: 22px;
    padding: 18px 40px;
  }

  &__document {
    div {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.72px;
    line-height: 22px;

    &:not(:last-child) {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #cdcdcd;
    }

    &--inactive,
    &--inactive .c-starts-modal__document--sub {
      color: #878787;
    }

    &--sub {
      font-weight: normal;
      color: #000302;
      font-size: 16px;
      letter-spacing: 0.64px;
      line-height: 24px;
      padding-top: 8px;
    }
  }

  &__document-btn {
    margin-left: 5px;
  }

  &__notes-header {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.72px;
    line-height: 22px;
    margin-bottom: 15px;
  }

  &__note {
    color: #4e4e4e;
    font-size: 16px;
    letter-spacing: 0.64px;
    line-height: 32px;
  }
}

//vars
$green: #009F00;
$black: #242424;
$blue: #1100FF;
$red: #FF0000;
$white: #FFFFFF;
$purple: rgb(158, 0, 255);
$navy: #0D1E34;
$light-white: rgba(255, 255, 255, .4);
$light-gray: #DADADA;
$gray-bg: #F6F6F6;
$sidbar-gray: #4E4E4E;
// $secondary-blue: #1D51A7;
// $orange: #E3531F;
// $blue-grey: #D3D9DE;
// $grey: #BFC6CD;
// $dark-grey: #656F7A;
// $light-blue-grey: #EEF5FB;
// $off-white: #F8FBFE;

// $subject-nav-blue: #397BE8;
// $subject-nav-border-blue:#6EA2F8;


//media sizes

$mobile-md: 375px;
$mobile-lg: 425px;
$tablet-portrait: 768px;
$tablet-landscape: 1024px;


%font-16 {
    font-family: 'Lato';
    font-size: 16px;
    letter-spacing: 0.2px;
    // line-height: 19px;
}
%font-16r {
  font-family: 'Lato';
  font-size: 14px;
  letter-spacing: 0.2px;
  @include breakpoint(lg) {
    font-size: 16px;
  }
  // line-height: 19px;
}

:root {
  --theme: #009F00
}

html {
  --usercolor: #e00005;
  --usergradient: linear-gradient(270deg, #f60707, #fa4f4f);
}
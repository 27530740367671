/*
  https: //adkgroup.atlassian.net/browse/AKAN-367
  Mobile - Portrait: 300 x 639 (uses mobile designs)
  Mobile - Landscape: 640 x 812 (uses mobile designs until 768, then uses tablet)
  Tablet - Portrait: 768 x 1023 (uses tablet design)
  Tablet - Landscape: 1024 x 1199 (uses tablet design)
  Laptop/Desktop: 1280+(uses desktop design)
  4K TV: 3840 x 2160 (uses 4K design)
*/
$breakpoints: (
  hxs: 'screen and ( max-height: 29em )',
  // 464px Horizontal mobile rules
  xxs: 'screen and ( min-width: 375px )',
  // 375px
  xs: 'screen and ( min-width: 30em )',
  // 480px
  sm: 'screen and ( min-width: 768px )',
  // 768px
  md: 'screen and ( min-width: 64em )',
  // 1024px
  lg: 'screen and ( min-width: 1280px )',
  // 1280px
  xl: 'screen and ( min-width: 160em )',
  // 1600px,
  xxl: 'screen and ( min-width: 3840px )',
  // 3840px
);

@mixin breakpoint($size) {
  $breakpoint-found: map-has-key($breakpoints, $size);

  @if $breakpoint-found ==true {
    $breakpoint: map-get($breakpoints, $size);

    @media #{$breakpoint} {
      @content;
    }
  }

  @else {
    @warn "Breakpoint size "+#{$size}+" doesn't exist.";
  }
}
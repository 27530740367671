#activity-board {
  display: grid;
  grid-gap: 2%;
  grid-template-columns: repeat(auto-fill, minmax(49%, 49%));
  grid-auto-rows: 30px;

  &.board-container--header {
    display: flex;
    flex-direction: column;

    @include breakpoint(sm) {
      display: block;
      column-count: 2;
      column-gap: 24px;

      &.board-container--header {
        height: initial;
      }
    }

    @include breakpoint(xxl) {
      column-count: 5;
    }

    .element-container {
      display: inline-grid;
      width: 100%;
      break-inside: avoid;
    }

    .activity-pane {
      .card-container .card-header .rightbox .add-job span.text {
        display: none;

        @include breakpoint(lg) {
          display: inline-block;
        }
      }

      .jobs-container {
        .job {
          .job__corp-name {
            font-size: 18px;
            font-weight: 700;

            @include breakpoint(lg) {
              display: none;
            }
          }

          .job-conf-line {
            @include breakpoint(lg) {
              display: none;
            }

            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
            color: #D0021B;
            font-size: 18px;
            text-align: right;
            word-wrap: break-word;
            position: relative;
            overflow: hidden;

            svg {
              color: #9B9B9B;
              margin-left: 6px;
            }

            &-text {
              line-height: initial;
              overflow: hidden;
              font-weight: 700;
            }
          }

          .job-header {
            .job-header-leftbox .job__corp-name {
              display: none;

              @include breakpoint(lg) {
                display: block;
              }
            }

            .job-header-rightbox {
              max-width: 50%;

              .job-conf-line {
                display: none;

                @include breakpoint(lg) {
                  display: flex;
                }
              }
            }
          }

          .job-line-break {
            margin: 1px 0px;

            @include breakpoint(lg) {
              margin: 10px 0px;
            }
          }

          .job-slot-container {
            .job-slot-header .job-slot-header-rightbox {

              svg:nth-of-type(1),
              svg:nth-of-type(2) {
                margin-right: 0;

                @include breakpoint(lg) {
                  margin-right: 5px;
                }
              }
            }

            .job-slot-line {
              flex-direction: column;
              align-items: flex-start;

              @include breakpoint(lg) {
                flex-direction: row;
                align-items: center;
              }

              .slotsub-leftbox {
                margin-left: -10px;

                @include breakpoint(lg) {
                  margin-left: 0;
                }
              }

              .slotsub-rightbox {
                align-self: flex-end;

                @include breakpoint(lg) {
                  align-self: center;
                }
              }

              .slotsub-rightbox .activity-sm {
                min-height: 44px;
                padding-right: 14px;

                @include breakpoint(lg) {
                  min-height: initial;
                  padding-right: 0;

                  svg {
                    height: 16px;
                    width: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .element-container {
    grid-row-end: span 9;
  }

  &.headerless {
    grid-template-columns: repeat(auto-fill, minmax(32%, 32%));

    .masonry-col {
      &:nth-child(2) {
        margin-left: 1%;
      }

      &:nth-child(3) {
        margin-left: 1%;
      }
    }

    .pencil,
    svg {
      transition: none !important;
    }
  }

  .masonry-col {
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }

  .element-container {
    margin-bottom: 3%;
  }

  .activity-pane {
    background: #FFFFFF;
    border-radius: 3px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);

    &.empty {
      display: none;
    }

    .card-container {
      grid-row-end: span 5;

      .card-header {
        border-radius: 3px 3px 0 0;
        color: #fff;
        padding: 10px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .leftbox {
          color: #FFFFFF;
          font-family: Lato;
          font-size: 18px;
          letter-spacing: 0.72px;
          line-height: 22px;

          img {
            border-radius: 10rem;
            width: 30px;
            height: 30px;
            margin-right: 15px;
          }

          .conf-line {
            margin-left: 8px;
            font-weight: bold;
            font-size: 20px;
          }

          align-items: center;
          display: flex;
        }

        .rightbox {
          cursor: pointer;
          color: #FFFFFF;
          font-family: Lato;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.56px;
          line-height: 17px;
          text-align: right;
          display: flex;
          align-items: center;

          div {
            display: flex;
            align-items: center;
          }

          svg {
            margin-left: 7px;
            transition: all 0.2s ease;
          }

          &:hover {
            svg {
              margin-left: 12px;
            }
          }

          &.headerless {
            font-size: 20px;
          }
        }
      }

      .card-header-border-bottom {
        width: 100%;
        height: 6px;
        background: #fff;
      }
    }

    .jobs-container {
      padding: 22px 32px;
      background-color: #FFFFFF;
      border-radius: 0px 0px 3px 3px;

      .job {
        .job-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .job-header-leftbox {
            a {
              z-index: 2;
            }

            color: #4A4A4A;
            font-family: Lato;
            font-size: 16px;
            letter-spacing: 0.64px;
            line-height: 25px;
            position: relative;
            min-width: 50%;
            flex: 1;


          }

          &__job-title-container {
            display: flex;
            align-items: center;
          }

          &__job-title {
            display: inline-block;
            position: relative;
            cursor: pointer;
            padding-right: 25px;

            &:hover {
              .job-id {
                &::after {
                  font-family: 'Font Awesome 5 Free';
                  font-weight: 300;
                  content: '\f2c2';
                  position: absolute;
                  right: -30px;
                  top: 0px;
                  font-size: 16px;
                  color: #173261;
                }
              }
            }
          }

          .job-id {
            display: inline-flex;
            align-items: center;
            position: relative;

            button {
              display: inline-flex;
              margin-left: 8px;
            }
          }

          .job-header-leftbox {
            .openings {
              font-weight: bold;
            }
          }

          .job-header-rightbox {
            max-width: 50%;

            .add-slot,
            .job-conf-line {
              justify-content: flex-end;
              display: flex;
              align-items: center;
              color: #9B9B9B;
              font-family: Lato;
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0.56px;
              line-height: 25px;
              cursor: pointer;

              svg {
                margin-left: 5px;
                transition: all 0.2s ease;
              }

              &:hover {
                svg {
                  margin-left: 9px;
                }
              }
            }

            .job-conf-line {
              color: #D0021B;
              font-size: 18px;
              text-align: right;
              word-wrap: break-word;
              position: relative;
              overflow: hidden;
              padding-right: 26px;

              svg {
                color: #9B9B9B;
                position: absolute;
                right: 0;
                top: 3px;
              }

              &-text {
                line-height: initial;
                overflow: hidden;
              }

              &.headerless {
                color: #000;
              }
            }
          }

          &.headerless {
            .job-conf-line {
              padding-right: 0;
            }
          }
        }

        .job-line-break {
          height: 1px;
          background-color: #D8D8D8;
          margin: 10px 0px;
        }

        .job-slot-container {
          .job-slot-break {
            height: 1px;
            background-color: #D8D8D8;
            margin: 15px 0px;
          }

          .job-slot-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .job-slot-header-leftbox {
              display: flex;
              align-items: center;
              color: #D0021B;
              font-family: Lato;
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0.56px;
              line-height: 17px;
            }

            .job-slot-header-rightbox {
              &.headerless {
                display: none;
              }

              display: flex;
              color: #9B9B9B;

              svg:nth-of-type(1),
              svg:nth-of-type(2) {
                margin-right: 5px;
              }

              svg {
                transition: all 0.2s ease;
                transform: scale(1);
                cursor: pointer;

                &:hover {
                  transform: scale(1.25);
                  color: rgb(60, 91, 109);
                }
              }
            }
          }

          .job-slot-line {
            margin: 10px 0px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .slotsub-leftbox {
              display: flex;
              align-items: center;

              .status {
                cursor: pointer;
              }

              .candidate-name {
                font-family: Lato;
                font-size: 16px;
                letter-spacing: 0.64px;
                line-height: 22px;
                cursor: pointer;
                position: relative;

                .hotInfo {
                  font-weight: 400;
                  font-size: 14px;
                }

                &:hover {
                  &::after {
                    font-family: 'Font Awesome 5 Free';
                    font-weight: 300;
                    content: '\f2c2';
                    position: absolute;
                    font-size: 16px;
                    margin-left: 7px;
                    color: #173261;
                  }
                }
              }

              .recruiter-initials {
                margin-left: 3px;
                letter-spacing: .2px;
              }
            }

            .slotsub-rightbox {
              display: flex;
              align-items: center;

              .activity-sm {
                cursor: pointer;
                color: #9B9B9B;
                font-size: 12px;
                display: inline-block;
                margin-left: 4px;
                transform: translateY(4px);

                .margin-spread {
                  text-align: right;
                  color: #000;
                  font-weight: bold;

                  .single-rate-field {
                    color: #666666;
                  }
                }

                .pencil {
                  transform: translateY(2px);
                  font-size: 16px;
                  margin-left: 0;
                  transition: all 0.2s ease;
                }

                &:hover {
                  .pencil {
                    margin-left: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.joborder-submissions {

  //the popover
  .popover {
    &.wide {
      li {
        .item-label {
          width: 100%;

          a {
            border-bottom: 1px solid black;
            z-index: 9999;
          }
        }
      }

      li:first-child {
        .item-label {
          border-top-right-radius: 1rem !important;
        }
      }

      li:last-child {
        .item-label {
          border-bottom-right-radius: 1rem !important;
        }
      }
    }
  }
}

.face2face-dialog {
  background: #fff;
  padding: 2rem;
  text-align: center;
  border-radius: 1rem;

  .dialog-btn {
    margin: 2rem 0.5rem;

    cursor: pointer;
    border-radius: 5px;
    border: 1px solid black;
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }
}

.activity-board {
  &__results-header {
    display: none;

    @include breakpoint(lg) {
      display: block;
    }

    @include breakpoint(xxl) {
      display: none;
    }

    padding: 40px 83px 32px;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;

    span {
      font-weight: bold;
    }
  }

  &__nr {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.6px;
    line-height: 15px;
    height: 23px;
    width: 89px;
    border: 1px solid #C80300;
    border-radius: 2px;
    background-color: #FF2C21;
    margin-left: 16px;
  }

  &__filters {
    margin: 10px 0;
    width: 100%;

    .c-toggle-tabs {
      display: flex;

      &__btn {
        flex: 1;
      }

      @include breakpoint(sm) {
        display: block;
      }
    }
  }
}
.c-starts-toggle {
  display: flex;
  height: 100%;
  width: 100%;
  @include breakpoint(lg) {
    display: block;
  }

  &__label {
    font-size: 14px;
    letter-spacing: 0.56px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  &__input {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px 0;
    @include breakpoint(lg) {
      padding: 10px;
    }
  }

  &__btn {
    height: 33px;
    display: flex;
    align-items: center;
  }

  .c-toggle-tabs {
    display: flex;
    height: 44px;
  
    @include breakpoint(lg) {
      height: 33px;
    }
  
    .c-btn {
      flex: 1;
    }
  }
}

.c-mobile-header {
  &__container {
    @include breakpoint(lg) {
      display: none;
    }
    z-index: 2;
    position: sticky;
    top: 0px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 1px 0 rgba(102, 102, 102, 0.25);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    &.hide {
      top: -50px;
    }
  }

  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  padding: 0 18px;

  &__title {
    color: #000000;
    font-family: Lato;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 20px;
    margin: 0;
    text-transform: capitalize;
  }
}

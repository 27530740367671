$alku-red: #e00005;

.ant-input {
  border: 1px solid #d8d8d8 !important;
}

.ant-select-dropdown-menu-item-active,
.ant-select-dropdown-menu-item-selected {
  background-color: #f8f8f8 !important;
}

.ant-select-selection {
  border-color: #d8d8d8 !important;
}

.ant-select-selection--multiple .ant-select-selection__choice__content {
  font-weight: bold;
}

.ant-select-dropdown-menu-item {
  font-weight: bold;
}

.ant-radio-checked .ant-radio-inner {
  border-width: 2px;
  border-color: $alku-red;
}
.ant-radio-inner:after {
  background-color: $alku-red;
}
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
  border-color: $alku-red;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px #ffe6e6;
}

.ant-layout {
  @include breakpoint(lg) {
    overflow: auto;
  }
}

.rc-virtual-list {
  .ant-select-item {
    line-height: 38px;
    @include breakpoint(lg) {
      line-height: 22px;
    }
  }
}

.ant-picker-panels {
  flex-direction: column;

  @include breakpoint(sm) {
    flex-direction: row;
  }
}
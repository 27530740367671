.user-card {
  margin: 6rem;
  transition: all 1.3s ease;
  position: absolute;
  opacity: 1;
  pointer-events: all;
  cursor: pointer;
  width: 90px;
  height: 90px;
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-radius: 10rem;
  transform: scale(1.175);

  &.inactive {
    opacity: 0;
    pointer-events: none;
  }
  .card-border {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 10rem;
    height: 100%;
  }
  img {
    // border-radius: 10rem;
    height: 90px;

  }
  .users-name {
    position: absolute;
    bottom: -4rem;
    font-size: 1.6rem;
    font-weight: 600;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    transition: all 1.3s ease;
    display: flex;
    justify-content: center;
    &.active {
      right: -12rem;
      bottom: 40%;
      font-size: 3.4rem;
      line-height: 2.9rem;
      color: $black;
      font-weight: bold;
      display: block;
    }
  }
}

.c-starts-mngmt {
  background: #F8F8F8;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  @include breakpoint(lg) {
    padding: 29px 55px;
  }
  @include breakpoint(xxl) {
    padding: 29px 0;
  }
  width: 100%;

 &__results-header {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: bold;
    padding-top: 24px;
    position: relative;
    @include breakpoint(sm) {
      flex-direction: row;
      justify-content: space-between;
    }
    @include breakpoint(lg) {
      font-size: 0.875rem;
      padding-bottom: 45px;
      padding-top: 16px;
      max-width: 1490px;
    }
    .seeing-results {
      display: none;
      @include breakpoint(lg) {
        display: block;
      }
    }
  }

  &__back-btn {
    text-align: left;
    color: #F92C2C;
    font-weight: bold;
  }

  &__selected {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @include breakpoint(sm) {
      flex-direction: row;
    }
    @include breakpoint(lg) {
      max-width: 1490px;
      justify-content: flex-start;
    }

    &.hidden {
      display: none;
    }
  }

  &__card {
    margin-right: 42px;
    margin-bottom: 20px;
    width: 100%;
    @include breakpoint(sm) {
      width: 450px;
    }
  }

  &__paperwork {
    width: 100%;
    flex: 1;
    max-width: 1000px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &.hidden {
      height: 0;
      overflow: hidden;
    }
    .c-consultant-card {
      flex: 100%;
      margin-bottom: 0.9375rem;

      @include breakpoint(lg) {
        max-width: 49%;
      }
      @include breakpoint(xxl) {
        max-width: 24%;
      }
    }
  }
}

.c-approve-btn {
  background-color: #EC2121;
  border-radius: 3px;
  color: #FFFF;
  font-size: 16px;
  font-weight: bold;
  height: 44px;
  margin: 24px 0;
  padding: 0.44rem 1.375rem;

  &.is-disabled {
    color: #696969;
    background-color: #D8D8D8;
    cursor: not-allowed;
  }

  &:hover {
    opacity: 0.8;
  }

  &--mngmt {
    right: 0;
    top: 0;

    @include breakpoint(lg) {
      margin: 0;
      position: absolute;
    }
  }

  &--card {
    margin-top: 5px;
    padding: 0.44rem 0.55rem;
  }
}
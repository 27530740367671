.c-department-card {
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  display: inline-flex;
  flex-direction: column;
  min-height: 78px;
  padding: 0;
  width: 160px;

  &:hover,
  &:focus {
    box-shadow: 0 0 17px 0 rgba(76, 76, 76, 0.2);
    cursor: pointer;
  }

  &__inner {
    align-items: center;
    color: #1a1a1a;
    display: flex;
    flex: 1;
    font-family: 'Poppins';
    font-size: 18px;
    @media screen and (max-width: 400px) {
      font-size: 16px;
    }
    justify-content: center;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    width: 100%;

    &.is-active {
      border: 2px solid #666666;
    }
  }

  &__div-color {
    background-color: #e6e6e6;
    display: block;
    height: 5px;
    width: 100%;
  }
}

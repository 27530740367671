.c-skeleton {
  &__input {
    &--full-width {
      .ant-skeleton.ant-skeleton-element {
        display: block;
        .ant-skeleton-input {
          width: 100%;
        }
      }
    }
  }
}

.c-skeleton-board {
  margin: 32px 85px 0;

  &__header {
    margin-bottom: 35px;
  }
  
  &__half-width-inputs {
    display: flex;
    div {
      width: 100%;
      .ant-skeleton.ant-skeleton-element {
        display: block;
        .ant-skeleton-input {
          width: 100%;
        }
      }
    }
  }
}

.c-ts-main {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  flex-flow: column;
  overflow: hidden;

  .board-container, section {
    height: auto;
    width: 100%;
    flex: 1;

    .c-dashboard__page {
      bottom: 220px;
    }

    .c-dashboard__pause {
      display: none;
    }
  }

  .filter-header {
    flex: none;
  }

  .activity-board__results-header {
    display: block;
    margin: 0 auto;
    max-width: 3402px;
    width: 100%;
  }
}

/// Mixin to customize scrollbar
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
/// @param {Color} $foreground-color - Scrollbar's color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
/// @example scss - Scrollbar styling
///   @include scrollbar(.5em, slategray);
@mixin scrollbar($size, $foreground-color, $background-color: mix($foreground-color, white,  60%)) {
  // For Google Chrome
  ::-webkit-scrollbar {
      width:  $size;
      height: $size;
  }
  ::-webkit-scrollbar:horizontal {
      display: none;
  }

  ::-webkit-scrollbar-thumb {
      background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
      background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
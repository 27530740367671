.c-simple-search {
  width: 100%;

  &__container {
    display: flex;
  }

  &__input {
    background-color: $gray-bg;
    border: none;
    width: 100%;

    &::placeholder {
      color: #9b9b9b;
      font-size: 14px;
    }
  }

  &__input-container {
    flex: 1;
    padding: 3px;
  }

  &__icon {
    align-items: center;
    display: flex;

    .fas {
      color: #d80808 !important;
      font-size: 18px !important;
    }
  }

  &__border-bottom {
    background: linear-gradient(270deg, #fb2f2f 0%, #d80808 100%);
    height: 3px;
    width: 100%;
  }
}

.c-onboarding-paperwork {
  background: linear-gradient(to right, #f8f8f8 30%, white 40%, white 100%);
  border-radius: 3px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  @include breakpoint(sm) {
    min-width: 354px;
  }

  &--fill-width {
    flex: 1;
  }

  &__header {
    background: linear-gradient(0deg, #191919 0%, #7c7c7c 100%);
    padding: 22px 30px;
    width: 100%;
  }

  &__header-text {
    color: #ffffff;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0.72px;
    line-height: 22px;
  }

  &__body {
    padding: 0 6px;
  }

  &__row {
    &:not(:last-child) {
      border-bottom: 1px solid #d8d8d8;
    }
  }

  &__item {
    display: flex;
    .c-onboarding-paperwork__col {
      padding-bottom: 8px;
    }

    &:first-child {
      .c-onboarding-paperwork__col {
        padding-top: 25px;
      }
    }
    &:last-child {
      .c-onboarding-paperwork__col {
        padding-bottom: 25px;
      }
    }
  }

  &__col {
    display: flex;
    align-items: center;
    @include breakpoint(lg) {
      padding: 0 9px;
    }

    &--left {
      word-break: break-word;
      background: #f8f8f8;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      width: 40%;
    }

    &--left-sub {
      padding-left: 8px;
      @include breakpoint(lg) {
        padding-left: 45px;
      }
    }

    &--right {
      color: #4a4a4a;
      flex: 1;
      width: 60%;

      @include breakpoint(lg) {
        padding-left: 30px;
      }

      > span {
        display: flex;
        align-items: center;
        @include breakpoint(lg) {
          &:first-child {
            margin-right: 40px;
          }
        }
      }

      .text {
        padding-left: 6px;
      }
    }

    &--comments {
      flex-direction: column;
    }
  }

  &__col-header,
  &__col-header.c-onboarding-paperwork__col {
    padding-bottom: 12px;
  }

  &__notes {
    font-size: 14px;
    border-color: #cacaca;
    display: block;
    margin-bottom: 13px;
    padding: 10px;
    resize: none;
    width: 100%;
  }
  &__save-notes-wrapper {
    align-self: flex-end;
    position: relative;
  }

  &__status {
    position: absolute;
    padding-top: 2px;
    color: #a2a2a2;
    font-size: 13px;
    text-transform: capitalize;

    &--saving {
      &:after {
        content: '.';
        animation: dots 1s steps(5, end) infinite;
      }
    }
  }

  &__radio-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 6px;
    width: 100%;

    @include breakpoint(lg) {
      flex-direction: row;
    }
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #a2a2a2;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #a2a2a2, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #a2a2a2, 0.5em 0 0 #a2a2a2;
  }
}

.c-filter-toggle-btn {
  color: #ee2323;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 21px;

  &--invert.c-filter-toggle-btn {
    &.active {
      background: #ee2323;
      color: white;
    }
  }

  &.active {
    background: #eeeeee;
    color: #4e4e4e;
  }

  &__icon {
    .fas {
      margin-left: 5px;
      color: #ee2323 !important;
      font-size: 12px !important;
    }
  }
}

.c-toggle-btn {
  --active-color: #F22525;
  display: inline-block;
  height: 25px;
  width: 47px;

  &__container {
    align-items: center;
    background: #D8D8D8;
    border-radius: 15px;
    cursor: pointer;
    display: inline-flex;
    height: 25px;
    position: relative;
    transition: all 0.3s ease-in;
    width: 47px;
  }

  &__input {
    position: absolute;
    left: -9999px;
    top: -9999px;
    appearance: none;

    &:checked + .c-toggle-btn__container {
      background:  var(--usergradientdark);

      .c-toggle-btn__tab {
        transform: translateX(100%);
      }
    }
  }

  &__tab {
    transition: all 0.3s ease-in;
    background-color: #FFFFFF;
    border-radius: 100%;
    border: 1px solid #B0B0B0;
    box-sizing: border-box;
    display: inline-block;
    height: 23px;
    position: absolute;
    width: 23px;
    transform: translateX(0);
  }
}

.c-go-live {
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0.56px;

  .c-toggle-btn {
    margin-right: 6px;
  }
}

.c-dealscard {
  padding: 10px 16px 16px 16px;
  &__title {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.88px;
    color: #1a1a1a;
    margin-bottom: 8px;
    font-weight: bold;
  }
  &__content {
    display: flex;
  }
  &__icon {
    background-color: #ededed;
    width: 88px;
    height: 88px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    &--lightblue {
      svg {
        color: #4ac0cb;
      }
    }
    &--blue {
      color: #4ac0cb;
    }
    &--purple {
      color: #694acb;
    }
    &--green {
      color: #3bba8e;
    }
    &--darkgreen {
      color: #4bb17f;
    }
    &--grape {
      color: #8d4fd3;
    }
    &--crimson {
      color: #cb4a7c;
    }
    &--yellow {
      color: #f1ae54;
    }
    &--orange {
      color: #ff9135;
    }
    &--skyblue {
      color: #35c0ff;
    }
    &--red {
      color: #f2589a;
    }
    &--grass {
      color: #59c1ad;
    }
    &--royal-blue {
      color: #4c4dd9;
    }
  }
  &__data {
    flex: 1;
    margin: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    > div {
      display: flex;
      align-items: center;
    }
    &.has-sub-brand {
      > div:not(:last-child) {
        margin-bottom: 7px;
      }
    }
    &__value {
      font-size: 24px;
      line-height: 20px;
      color: #6d6c6c;
      text-align: right;
      &--sm {
        flex: 1;
        padding-right: 10px;
        max-width: 120px;
      }
      &--md {
        flex: 0.6;
        padding-right: 8px;
        max-width: 150px;
      }
      &--lg {
        flex: 0.7;
        padding-right: 6px;
        max-width: 170px;
      }
    }
    &__label {
      width: 52px;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.75px;
      color: #6c6c6c;
      // &--sm {
      //   flex: 2;
      // }
      &--md {
        flex: 0.4;
      }
      &--lg {
        flex: 0.3;
      }

      &.has-sub-brand {
        width: 64px;
      }
    }
  }
}

.c-dealsgrid {
  > div {
    @include breakpoint(sm) {
      display: flex;
      margin: 0 -8px 12px -8px;
    }
    > div {
      flex: 1;
      margin-bottom: 16px;
      @include breakpoint(sm) {
        margin: 0 8px;
      }
    }
  }
}

.c-dealprogress {
  display: none;
  justify-content: flex-start;
  padding: 10px 0 0 10px;
  position: relative;
  @include breakpoint(sm) {
    display: flex;
  }

  &__barchart {
    overflow-x: scroll;
    padding-bottom: 20px;
    position: relative;

    // for webkit-browsers, this changes the default behavior on macOs which hides the scrollbar until the user scrolls
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    &--headerless::-webkit-scrollbar {
      display: none;
      padding-bottom: 0;
    }
  }

  &__yaxis {
    color: #3b3b3b;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    height: 256px;
    padding-right: 10px;
    > div:first-child,
    > div:last-child {
      opacity: 0;
    }
  }

  &__total {
    position: absolute;
    border-radius: 5px;
    z-index: 10;
    right: 0;
    top: 50px;
    width: 126px;
    height: 126px;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    padding: 13px;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.13);
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.88px;
    color: #696969;
    > div {
      text-align: center;
    }
    > div:first-child {
      font-size: 56px;
      line-height: 60px;
      letter-spacing: normal;
      color: #4e4e4e;
    }
  }

  &--vertical {
    background-color: white;

    @include breakpoint(sm) {
      display: none;
    }
    &__div-text {
      color: #333333;
      font-family: Lato;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.4px;
    }

    div.recharts-wrapper {
      .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis > line {
        display: none;
      }
      .recharts-surface {
        .recharts-cartesian-axis-ticks {
          .recharts-layer.recharts-cartesian-axis-tick {
            > line {
              display: none;
            }
          }
        }
        .recharts-cartesian-grid .recharts-cartesian-grid-vertical {
          line:last-child, line:nth-last-child(2) {
            display: none;
          }
        }
      }
    }
  }
}

.g-circle-percent {
  border-radius: 50%;
  border: 3px solid #694acb;
  width: 59px;
  height: 59px;
  text-align: center;
  color: #694acb;
  padding-top: 3px;
  font-size: 40px;
  position: relative;
  &__line {
    padding-left: 4px;
    transform: rotate(30deg);
  }
  &__dot {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 3px solid #694acb;
    &--front {
      top: 12px;
      left: 12px;
    }
    &--back {
      right: 12px;
      bottom: 12px;
    }
  }
  &--month {
    border-color: #3bba8e;
    color: #3bba8e;
    .g-circle-percent__dot {
      border-color: #3bba8e;
    }
  }
}

.c-deal-division-table {
  &__container {
    overflow: auto;
  }
  width: 100%;

  &.mobile {
    border: 1px solid #d8d8d8;
    font-family: 'Open Sans';
    @include breakpoint(sm) {
      display: none;
    }
  }
  &.desktop {
    display: none;
    @include breakpoint(sm) {
      display: table;
      min-width: 940px;
    }
    @include breakpoint(lg) {
      min-width: auto;
    }
  }

  &__header {
    padding-bottom: 16px;
    &:after {
      content: '-';
      display: block;
      line-height: 1em;
      color: transparent;
    }
  }

  &__board {
    border: 8px solid #fff;
    box-shadow: 0 1px 17px 0 rgba(0, 0, 0, 0.1);
  }

  &__row {
    position: relative;
    background: white;

    // a little hacky but this is the only way to get the border to not be full width in a table
    &:not(:last-child) {
      border-bottom: 1px solid #d8d8d8;
      td {
        &:not(:first-child):after {
          margin-top: 16.4px;
        }
      }
    }

    &.mobile {
      display: flex;
      padding: 13px 16px;
      & .g-numbercard.g-numbercard--xs {
        padding-top: 0;
      }
    }
  }

  &__cell {
    color: #666666;
    font-size: 18px;

    &:first-child {
      padding-left: 8px;
    }
    &:last-child {
      padding-right: 8px;
    }

    &--division {
      color: #000000;
      font-size: 16px;
      font-weight: bold;
    }

    &--header {
      color: #666666;
      font-size: 14px;
      text-align: left;
    }

    &-division.mobile {
      color: #666666;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 16px;
    }

    &-label.mobile, &-value.mobile {
      color: #555555;
      font-size: 14px;
      line-height: 20px;
    }
    &-value.mobile {
      color: #333333;
    }
  }

  &__sort-icon {
    margin-left: 5px;
    display: inline-flex;
    flex-direction: column;
    cursor: pointer;

    .material-icons {
      line-height: 0.25;
    }
  }
}

.c-input {
  display: flex;
  height: 30px;
  max-width: 210px;

  &__prefix-block {
    align-items: center;
    background-color: #0B8B54;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    color: #FFFFFF;
    display: flex;
    font-size: 22px;
    justify-content: center;
    width: 30px;
  }
  input {
    border-bottom-right-radius: 5px;
    border-left: none;
    border-top-right-radius: 5px;
    border: 1px solid #999999;
    color: #4E4E4E;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 24px;
    padding-left: 10px;
    width: 100%;
  }
}

.c-select {
  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--usercolor);
  }

  &__dropdown {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: white;
      background: var(--usergradient);
    }
  }
}